import React, { useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import { Input } from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import Datepicker from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";
import AuthActions from "../../redux/auth/actions";
import ComplimentaryTicketsWrapper from "../../views/CompilmentaryTickets/complimentary.style";
import PageTitle from "components/common/PageTitle";
import { allEvents, get } from "../../api/ticket";
import {
  getTickets,
  getTicketDetails,
  bookTickets
} from "../../api/complimentary_tickets";
import { Row, Col, Space, Card } from "antd";
import patient1 from "../../assets/images/themes/patient1.svg";
import LoaderComponent from "components/common/LoaderComponent";
import CustomToast from "components/notifications/CustomToast";

let optionsNew = [];

const { logout } = AuthActions;

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const BookComplimentaryTickets = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    loading,
    submitCount,
    onSubmit,
    values,
    action,
    setFieldTouched
  } = props;
  const [selected, setSelected] = useState([]);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [offerType, setOfferType] = useState("");
  const [eventList, setEventList] = useState([]);
  const [counter, setCounter] = useState(1);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [ticketDateArr, setticketDateArr] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [ticketLevel, setTicketLevel] = useState([]);
  const [ticketSection, setTicketSection] = useState([]);
  const [ticketRow, setTicketRow] = useState([]);
  const [ticketInfos, setTicketInfos] = useState([]);

  useEffect(() => {
    getAllEvents();
  }, []);

  useEffect(() => {
    setFieldValue("ticket_ids", selected);
    handleTicketDetails();
  }, [selected]);

  useEffect(() => {
    if (props.editedData) {
      const newTickets = props.editedData.offer_ticket.map(ticketData => {
        return {
          label: ticketData.ticket.name_en,
          value: ticketData.ticket_id
        };
      });
      setSelected(newTickets);
    }
  }, [props.editedData]);

  // useEffect(()=>{
  //   handleTicketDetails();
  // },[selected])

  const getAllEvents = () => {
    allEvents()
      .then(data => {
        setEventList(data.data);
        setTicketDetails([]);
        setticketDateArr([]);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          props.logout();
        }
      });
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleBookTickets = () => {
    console.log("ticket arr", ticketDateArr);
    if (!ticketDateArr.length) {
      setSnackBar({
        flag: true,
        heading: "error",
        className: "c-danger",
        description: "Please fill all the details to book tickets"
      });
      return;
    }
    if (ticketDateArr && ticketDateArr.length === ticketDetails.length) {
      const arr = ticketDateArr.map(x => ({
        event_id: values.event_id,
        ticket_id: x.ticket_id,
        ticket_info_id: x.id,
        quantity: x.totalQuantityInitial,
        price_per_ticket: 0,
        ticket_total_amount: 0
      }));
      let total = null;
      for (var i in arr) {
        total += arr[i].quantity;
      }

      if (total !== props.location.state.number_of_tickets) {
        setSnackBar({
          flag: true,
          heading: "error",
          className: "c-danger",
          description: `Please provide ${props.location.state.number_of_tickets} complimentary tickets`
        });
        return;
      } else {
        console.log("success");
        const params = {
          user_id: props.location.state.referral_id,
          total_amount: 0,
          wallet_amout: 0,
          order_tickets: arr,
          complimentary_ticket_id: props.location.state.id
        };

        bookTickets(params)
          .then(res => {
            console.log("res is", res);
            setSnackBar({
              flag: true,
              heading: "success",
              className: "c-success",
              description: "Tickets Booked successfully"
            });
            props.history.push("/complimentary-tickets");
          })
          .catch(err => {
            console.log("err", err);
          });
      }
    } else {
      setSnackBar({
        flag: true,
        heading: "error",
        className: "c-danger",
        description: "Please Fill all the details to book tickets"
      });
    }
  };

  const handleTicketInfo = (id, data) => {
    const ticketObj = data.find(x => x.id === Number(id));
    let newObj = { ...ticketObj, totalQuantityInitial: 1 };
    let arr = ticketDateArr;
    if (newObj) {
      arr = [...arr, newObj];
    }
    setticketDateArr(arr);
  };

  const handleCountDecrement = (index, data, data1) => {
    let updatedArr = [...ticketDateArr];
    let newQuantityObj = { ...updatedArr[index] };
    if (newQuantityObj.ticket_id === Number(data)) {
      if (newQuantityObj.totalQuantityInitial > 1) {
        newQuantityObj.totalQuantityInitial =
          newQuantityObj.totalQuantityInitial - 1;
        updatedArr[index] = newQuantityObj;
      }
    }
    setticketDateArr(updatedArr);
  };

  const handleCountIncrement = (index, data, data1) => {
    let updatedArr = [...ticketDateArr];
    let newQuantityObj = { ...updatedArr[index] };
    if (newQuantityObj.ticket_id === Number(data)) {
      if (newQuantityObj.available_qty > 0) {
        if (
          newQuantityObj.totalQuantityInitial <
          props.location.state.number_of_tickets
        ) {
          newQuantityObj.totalQuantityInitial =
            newQuantityObj.totalQuantityInitial + 1;
          updatedArr[index] = newQuantityObj;
        }
      } else {
        return;
      }
    }
    setticketDateArr(updatedArr);
  };

  const handleTicket = id => {
    setSelected([]);
    const params = {
      event_id: id
    };
    getTickets(params)
      .then(result => {
        result.data.map(values => {
          optionsNew.push({
            label: values.name + "(" + values.ticket_type.title + ")",
            value: values.id
          });
        });
        setTicketOptions(optionsNew);
        setticketDateArr([]);
        optionsNew = [];
      })
      .catch(error => {
        console.log("error", error);
      });
  };

  const handleTicketDetails = () => {
    let newTicketsArr = [];
    if (
      selected.length &&
      selected.length <= props.location.state.number_of_tickets
    ) {
      setLoader(true);
      selected.map(result => {
        newTicketsArr.push(result.value);
      });
      const params = {
        tickets: JSON.stringify(newTicketsArr)
      };
      getTicketDetails(params)
        .then(res => {
          setLoader(false);
          //setTicketDetails(newTicketsData);
          let newArr = res.data.map(res => {
            if (res.ticket_type_id === 5) {
              let newLevelArr = [...ticketLevel];
              res.ticket_levels.map(ticket_level => {
                newLevelArr.push(ticket_level);
              });
              setTicketLevel(newLevelArr);
            }
            return { ...res, quantity: 1 };
          });
          setTicketDetails(newArr);
          setticketDateArr([]);
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            console.log("err", err);
          }
        });
    } else if (!selected.length) {
      setTicketDetails([]);
      setTicketLevel([]);
    } else {
      return;
    }
  };

  console.log("ticket levels", ticketLevel);

  const handleTicketLevel = ticket_level_id => {
    console.log("ticket level id", ticket_level_id);
    setTicketSection([]);
    setTicketRow([]);
    const newTicketSection = [...ticketLevel];
    const selectedLevel = newTicketSection.filter(
      res => res.id === Number(ticket_level_id)
    );
    console.log("selected level", selectedLevel);
    const newSectionArr = [];
    if (selectedLevel.length) {
      selectedLevel[0].ticket_sections.map(ticket_section => {
        newSectionArr.push(ticket_section);
      });
    }

    setTicketSection(newSectionArr);
    // let newTicketArray1 = [...ticketDetails];
    // newTicketArray1.map((res,index)=>{
    // let newResponse = res.ticket_levels.filter((ticket_level,index)=>{
    //   console.log('ticket levels',ticket_level.id)
    //     return ticket_level.id == ticket_level_id;
    // })
    // // let index = res.ticket_levels.findIndex(({id})=>id === ticket_level_id);

    // console.log('new response',newResponse);
    // let newSection = [];
    // newResponse[0].ticket_sections.forEach(section=>{
    //   newSection.push({
    //     label: section.title,
    //     value: section.id
    //   })
    // })
    // res.ticket_levels = newResponse;
    // });

    // console.log('new tick arr',newTicketArray1)

    //newTicketArray1.splice(index,1,res);
    //setTicketDetails(newTicketArray1);
  };
  const handleTicketSection = id => {
    console.log("ticket section id", id);
    setTicketRow([]);
    const newTicketRow = [...ticketSection];
    const selectedSection = newTicketRow.filter(res => res.id === Number(id));
    const newRowArr = [];
    selectedSection[0].ticket_rows.map(ticket_row => {
      newRowArr.push(ticket_row);
    });
    setTicketRow(newRowArr);
  };
  const handleTicketRow = id => {
    const newTicketInfo = [...ticketRow];
    const selectedInfo = newTicketInfo.filter(res => res.id === Number(id));
    const newRowArr = [];
    selectedInfo[0].ticket_infos.map(ticket_info => {
      newRowArr.push(ticket_info);
    });
    setTicketInfos(newRowArr);
  };

  const handleTicketDate = (dateValue, ticket_info) => {
    const ticketObj = ticket_info.find(x => x.id == dateValue);
    let newObj = { ...ticketObj, totalQuantityInitial: 1 };
    let arr = [...ticketDateArr];
    if (newObj) {
      arr = [...arr, newObj];
    }
    setticketDateArr(arr);
  };

  // console.log('ticket details',ticketDetails);
  console.log("ticket date arr", ticketDateArr);
  // console.log('ticket section',ticketSection);
  // console.log('ticket row',ticketRow);
  return (
    <ComplimentaryTicketsWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.complimentary"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.complimentary"
            }
            // {
            //   name: hasParam ? "action.edit" : "action.add"
            // }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/complimentary-tickets")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div>
        <form>
          <div className="form-group">
            <label className="fs-16 medium-text">Event</label>
            <Input
              onClick={() => handleTicket(values.event_id)}
              type="select"
              name="event_id"
              id="event_id"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.event_id}
            >
              <option value="">Select Type</option>
              {eventList &&
                eventList.map((event, i) => (
                  <option value={event.id} key={`option${i}`}>
                    {event.name}
                  </option>
                ))}
            </Input>
            <Error field="event_id" />
          </div>
          {ticketOptions && ticketOptions.length ? (
            <div className="form-group">
              <label className="fs-16 medium-text">Ticket</label>
              <MultiSelect
                hasSelectAll={false}
                options={ticketOptions}
                value={selected}
                onChange={setSelected}
                labelledBy="Select Tickets"
              />
              <Error field="ticket_ids" />
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
      <hr></hr>
      <div>Tickets added in cart</div>
      <Row xs={24} sm={24} md={24} lg={24}>
        {!ticketDetails.length ? (
          <div>Please Select Ticket to get records</div>
        ) : loader ? (
          <LoaderComponent />
        ) : (
          ticketDetails &&
          ticketDetails.map((ticketData, index) => {
            return (
              <Col
                key={index}
                xs={24}
                sm={24}
                md={24}
                lg={24}
                className="mr-10 mb-20"
              >
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: "100%" }}
                >
                  <Card className="review-box">
                    <div>
                      <div className="header">
                        <Space size="large">
                          <div className="reviews-left flex">
                            <div className="mr-20">
                              <img
                                className="sender-image"
                                src={ticketData.seating_map_image}
                                alt="loading..."
                                style={{ borderRadius: "50%" }}
                              />
                            </div>
                            <div>
                              <div className="flex">
                                <div className="mr-10 title-view">
                                  Ticket Name:
                                </div>
                                <div className="P-SemiBold fs-15">
                                  {ticketData.name}
                                </div>
                              </div>
                              {ticketData.ticket_type.title !== "NORMAL" ? (
                                <div className="flex">
                                  <div className="mr-10 title-view">
                                    Date & Time:
                                  </div>
                                  <div className="P-SemiBold fs-15">
                                    <select
                                      onChange={e => {
                                        if (e.target.value !== "") {
                                          handleTicketDate(
                                            e.target.value,
                                            ticketData.ticket_infos
                                          );
                                        } else {
                                          let newArr = [...ticketDateArr];
                                          let newRes = newArr.filter(
                                            id => id.ticket_id !== ticketData.id
                                          );
                                          setticketDateArr(newRes);
                                        }
                                      }}
                                    >
                                      <option value="">Select Type</option>
                                      {ticketData.ticket_infos.length &&
                                        ticketData.ticket_infos.map(
                                          (ticket_info, i) => (
                                            <option
                                              defaultValue={ticket_info.id}
                                              value={ticket_info.id}
                                              key={`option${i}`}
                                            >
                                              {moment(
                                                ticket_info.from_date
                                              ).format("DD-MM-YYYY")}
                                            </option>
                                          )
                                        )}
                                    </select>
                                    {/* <Error field="event_id" /> */}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="flex">
                                <div className="mr-10 title-view">Venue:</div>
                                <div className="P-SemiBold word-break fs-15">
                                  {ticketData.venue}
                                </div>
                              </div>
                              <div className="flex">
                                <div className="mr-10 title-view">
                                  Description:
                                </div>
                                <div className="P-SemiBold word-break fs-15">
                                  {ticketData.description}
                                </div>
                              </div>
                              <div className="flex">
                                <div className="P-SemiBold mr-10 title-view">
                                  Ticket Type:
                                </div>
                                <div className="P-SemiBold fs-15 ">
                                  {ticketData.ticket_type.title}
                                </div>
                              </div>
                              {ticketData.ticket_type.title === "NORMAL" ? (
                                <div>
                                  <div className="flex">
                                    <div className="P-SemiBold mr-10 title-view">
                                      Ticket Level:
                                    </div>
                                    <div className="P-SemiBold fs-15">
                                      <select
                                        onChange={e => {
                                          if (e.target.value !== "") {
                                            handleTicketLevel(e.target.value);
                                          } else {
                                            let newArr = [...ticketDateArr];
                                            let newRes = newArr.filter(
                                              id =>
                                                id.ticket_id !== ticketData.id
                                            );
                                            console.log("new res is", newRes);
                                            setticketDateArr(newRes);
                                            setTicketSection([]);
                                            setTicketRow([]);
                                            setTicketInfos([]);
                                          }
                                        }}
                                      >
                                        <option value="">Select</option>
                                        {ticketLevel &&
                                          ticketLevel.map((ticket_level, i) => (
                                            <option
                                              value={ticket_level.id}
                                              key={`option${i}`}
                                            >
                                              {ticket_level.title}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                  {ticketSection && ticketSection.length ? (
                                    <div className="flex">
                                      <div className="P-SemiBold mr-10 title-view">
                                        Ticket Section:
                                      </div>
                                      <div className="P-SemiBold fs-15">
                                        <select
                                          onChange={e => {
                                            if (e.target.value !== "") {
                                              handleTicketSection(
                                                e.target.value
                                              );
                                            } else {
                                              let newArr = [...ticketDateArr];
                                              let newRes = newArr.filter(
                                                id =>
                                                  id.ticket_id !== ticketData.id
                                              );
                                              setticketDateArr(newRes);
                                              setTicketRow([]);
                                              setTicketInfos([]);
                                            }
                                          }}
                                        >
                                          <option value="">Select Type</option>
                                          {ticketSection.map(
                                            (ticket_section, i) => (
                                              <option
                                                value={ticket_section.id}
                                                key={`option${i}`}
                                              >
                                                {ticket_section.title}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {ticketRow && ticketRow.length ? (
                                    <div className="flex">
                                      <div className="P-SemiBold mr-10 title-view">
                                        Ticket Row:
                                      </div>
                                      <div className="P-SemiBold fs-15">
                                        <select
                                          onChange={e => {
                                            if (e.target.value !== "") {
                                              handleTicketRow(e.target.value);
                                            } else {
                                              let newArr = [...ticketDateArr];
                                              let newRes = newArr.filter(
                                                id =>
                                                  id.ticket_id !== ticketData.id
                                              );
                                              setticketDateArr(newRes);
                                              setTicketInfos([]);
                                            }
                                          }}
                                        >
                                          <option value="">Select Type</option>
                                          {ticketRow.map((ticket_row, i) => (
                                            <option
                                              value={ticket_row.id}
                                              key={`option${i}`}
                                            >
                                              {ticket_row.title}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {ticketInfos && ticketInfos.length ? (
                                    <div className="flex">
                                      <div className="P-SemiBold mr-10 title-view">
                                        Date & Time:
                                      </div>
                                      <div className="P-SemiBold fs-15">
                                        <select
                                          onChange={e => {
                                            if (e.target.value !== "") {
                                              handleTicketInfo(
                                                e.target.value,
                                                ticketInfos
                                              );
                                            } else {
                                              let newArr = [...ticketDateArr];
                                              let newRes = newArr.filter(
                                                id =>
                                                  id.ticket_id !== ticketData.id
                                              );
                                              setticketDateArr(newRes);
                                            }
                                          }}
                                        >
                                          <option value="">Select Type</option>
                                          {ticketInfos.map((ticket_info, i) => (
                                            <option
                                              value={ticket_info.id}
                                              key={`option${i}`}
                                            >
                                              {moment(
                                                ticket_info.from_date
                                              ).format("DD-MM-YYYY")}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="flex">
                                <div className="mr-10 title-view">
                                  Total Quantity:
                                </div>
                                <div className=" P-Regular">
                                  <div className=" P-Regular">
                                    <button
                                      className="decrement"
                                      onClick={() =>
                                        handleCountDecrement(
                                          index,
                                          ticketData.id,
                                          ticketData
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-minus "
                                        aria-hidden="true"
                                      ></i>
                                    </button>

                                    <input
                                      disabled
                                      className="increment-decrement-input"
                                      type="number"
                                      value={
                                        ticketDateArr && ticketDateArr.length
                                          ? ticketDateArr[index]
                                              ?.totalQuantityInitial
                                          : 1
                                      }
                                    ></input>

                                    <button
                                      className="increment"
                                      onClick={() =>
                                        handleCountIncrement(
                                          index,
                                          ticketData.id,
                                          ticketData
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-plus "
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* <div className = "flex">
                                <div className="P-SemiBold mr-10 title-view">
                                  Start Seat Number:
                                </div>
                                <div className="P-SemiBold fs-15">
                               {ticketData.ticket_details.fulfillmentValue.ticket_infos[0].seat}
                                </div>
                              </div> */}
                              {/* <div className = "flex">
                                <div className="P-SemiBold mr-10 title-view">
                                  End Seat Number:
                                </div>
                                <div className="P-SemiBold fs-15">
                                  {ticketData.ticket_details.fulfillmentValue.ticket_infos[0].end_seat_number}
                                </div>
                              </div>
                              <div className = "flex">
                                <div className="P-SemiBold mr-10 title-view">
                                  Total Price:
                                </div>
                                <div className="P-SemiBold fs-15">
                                 $0
                                </div>
                              </div>
                              <div className = "flex">
                                <div className = "mr-10 title-view">
                                  Total Quantity:
                                </div>
                                <div className=" P-Regular">
                                  <div className=" P-Regular">
                                 <button className = "decrement" id = {ticketData.ticket_details.fulfillmentValue.id} onClick = {()=>handleCountDecrement(ticketData.ticket_details.fulfillmentValue.id)}><i className="fa fa-minus " aria-hidden="true"></i></button>
                                  <input className = "increment-decrement-input" type = "number" id = {ticketData.ticket_details.fulfillmentValue.id} value = {ticketData.quantity} >

                                  </input>
                                    <button className = "increment" onClick = {()=>handleCountIncrement(ticketData.ticket_details.fulfillmentValue.id,ticketData.remaining_qty)}><i className="fa fa-plus " aria-hidden="true"></i></button>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </Space>
                        {/* <div className="P-Regular grey-text reviews-right">
                          {moment(new Date()).format("DD/MM/YYYY")}
                        </div> */}
                      </div>
                    </div>
                  </Card>
                </Space>
              </Col>
            );
          })
        )}
      </Row>
      <div className="new-button">
        <Button className="c-btn c-success " onClick={handleBookTickets}>
          <div className="fs-14 medium-text" title="Edit">
            Book Tickets
          </div>
        </Button>
      </div>
      <CustomToast
        heading={snackBar.heading}
        width={400}
        show={snackBar.flag}
        transition
        position="top-middle"
        className={snackBar.className}
        message={snackBar.description}
        onCloseCLick={() => setSnackBar(initSnackBar)}
      />
    </ComplimentaryTicketsWrapper>
  );
};

export default compose(
  enhancer,
  connect(null, { logout })
)(BookComplimentaryTickets);
