import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const statistics = (page, limit, search) => {
  Headers.setHeaders();

  return axios.get(`${BASE_URL}/admin/v1/statistics`).then(statistics => {
    return statistics.data;
  });
};

export { statistics };
