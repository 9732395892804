import React, { useState, useEffect } from "react";
import AdminWrapper from "./admin.style";
import AdminForm from "components/admins/AdminForm";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import {
  adminTypes,
  createProfile,
  createAdmin,
  updateAdminDetails
} from "../../api/admin";
import CustomToast from "components/notifications/CustomToast";

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const AdminEdit = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [editedData, setEditedData] = useState(null);
  const hasParam = props.match.params.hasOwnProperty("id");
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hasParam) {
      const {
        admin_role,
        name,
        email,
        id,
        image,
        mobile
      } = props.location.state;
      setEditedData({ role: admin_role, name, email, id, image, mobile });
    }
  }, [props.location.state, hasParam]);

  const submitFormHandler = (data, action) => {
    const { mobile, email, name, role, image, password } = data;
    const formData = new FormData();

    if (action === "add") {
      formData.append("image", image);
      formData.append("mobile", mobile);
      formData.append("email", email);
      formData.append("name", name);
      formData.append("admin_role_id", role);
      formData.append("password", password);
      setLoading(true);
      createAdmin(formData)
        .then(response => {
          setSnackBar({
            flag: true,
            heading: "Admin Created",
            className: "c-success",
            description: "Admin Has been created successfully!"
          });
        })
        .then(response => {
          setLoading(false);
          setTimeout(() => {
            props.history.push("/admins");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          setSnackBar({
            flag: true,
            className: "c-danger",
            heading: "Something went wrong",
            description: error.response && error.response.data.message
          });
        });
    } else if (action === "edit") {
      if (typeof image === "object") {
        formData.append("image", image);
      }
      formData.append("mobile", mobile);
      formData.append("name", name);
      formData.append("admin_role_id", role);
      setLoading(true);
      updateAdminDetails(props.match.params.id, formData)
        .then(response => {
          setSnackBar({
            flag: true,
            heading: "Admin Details Saved",
            className: "c-success",
            description: "Admin Has been updated successfully!"
          });
          setLoading(false);
          setTimeout(() => {
            props.history.push("/admins");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          setSnackBar({
            flag: true,
            className: "c-danger",
            heading: "Something went wrong",
            description: error.response.data.message
          });
        });
    }
  };

  useEffect(() => {
    adminTypes().then(admin => {
      setTypes(admin.data);
    });
  }, []);

  return (
    <AdminWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.admins"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.admins"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/admins")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Admin
            </div>
          </div>
          <div className="roe-card-body">
            <AdminForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              types={types || []}
              loading={loading}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
      </div>
      <CustomToast
        heading={snackBar.heading}
        width={400}
        show={snackBar.flag}
        transition
        position="top-middle"
        className={snackBar.className}
        message={snackBar.description}
        onCloseCLick={() => setSnackBar(initSnackBar)}
      />
    </AdminWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(AdminEdit);
