import Dashboard from "views/Dashboard";

import Admin from "views/Admins/Admin";
import AdminEdit from "views/Admins/AdminEdit";
import AdminView from "views/Admins/AdminView";
import AdminProfile from "views/Admins/AdminProfile";
import ChangePassword from "views/Admins/ChangePassword";

import Customer from "views/Customers/Customer";
import CustomerEdit from "views/Customers/CustomerEdit";
import CustomerView from "views/Customers/CustomerView";

import Referral from "views/Referrals/Referral";
import ReferralEdit from "views/Referrals/ReferralEdit";
import ReferralView from "views/Referrals/ReferralView";
import ReferralAverageRating from "views/Referrals/ReferralAverageRating";
import ReferralAverageRatingCount from "views/Referrals/ReferralAverageRatingCount";

import Event from "views/Events/Event";
import EventEdit from "views/Events/EventEdit";
import EventView from "views/Events/EventView";

import StaticPage from "views/StaticPages/StaticPage";
import StaticPageEdit from "views/StaticPages/StaticPageEdit";
import StaticPageView from "views/StaticPages/StaticPageView";

import Setting from "views/Settings/Setting";
import SettingEdit from "views/Settings/SettingEdit";
import SettingView from "views/Settings/SettingView";

import Ticket from "views/Tickets/Ticket";
import TicketEdit from "views/Tickets/TicketEdit";
import TicketView from "views/Tickets/TicketView";

import MailTemplate from "views/MailTemplates/MailTemplate";
import MailTemplateEdit from "views/MailTemplates/MailTemplateEdit";
import MailTemplateView from "views/MailTemplates/MailTemplateView";

import Contact from "views/Contact/Contact";
import ContactView from "views/Contact/ContactView";

import Order from "views/Order/Order";
import OrderView from "views/Order/OrderView";

import OrderTransaction from "views/Transaction/Order";
import WithdrawTransaction from "views/Transaction/Withdraw";

import Rating from "views/Ratings/Rating";
import RatingView from "views/Ratings/RatingView";

import Offers from "views/Offers/Offers";
import OfferEdit from "views/Offers/OfferEdit";
import OfferView from "views/Offers/OfferView";

import Transfer from "views/Transfer/Transfer";
import TransferView from "views/Transfer/TransferView";
import ComplimentaryTickets from "views/CompilmentaryTickets/ComplimentaryTickets";
import BookComplimentaryTickets from "components/complimentaryTickets/BookComplimentaryTickets";
import ComplimentaryTicketsView from "views/CompilmentaryTickets/ComplimentaryTicketsView";

const dashboardRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/profile", component: AdminProfile },
  { path: "/changePassword", component: ChangePassword },
  { path: "/admins", component: Admin },
  { path: "/admins/add", component: AdminEdit },
  { path: "/admins/view/:id", component: AdminView },
  { path: "/admins/edit/:id", component: AdminEdit },

  { path: "/customers", component: Customer },
  { path: "/customers/add", component: CustomerEdit },
  { path: "/customers/view/:id", component: CustomerView },
  { path: "/customers/edit/:id", component: CustomerEdit },

  { path: "/referrals", component: Referral },
  { path: "/referrals/add", component: ReferralEdit },
  { path: "/referrals/view/:id", component: ReferralView },
  { path: "/referrals/edit/:id", component: ReferralEdit },
  { path: "/referrals/average-ratings/:id", component: ReferralAverageRating },
  {
    path: "/referrals/average-ratings-count/:id",
    component: ReferralAverageRatingCount
  },

  { path: "/offers", component: Offers },
  { path: "/offers/add", component: OfferEdit },
  { path: "/offers/view/:id", component: OfferView },
  { path: "/offers/edit/:id", component: OfferEdit },

  { path: "/transfer", component: Transfer },
  { path: "/transfer/view/:id", component: TransferView },

  { path: "/events", component: Event },
  { path: "/events/add", component: EventEdit },
  { path: "/events/view/:id", component: EventView },
  { path: "/events/edit/:id", component: EventEdit },

  { path: "/staticPages", component: StaticPage },
  { path: "/staticPages/add", component: StaticPageEdit },
  { path: "/staticPages/view/:id", component: StaticPageView },
  { path: "/staticPages/edit/:id", component: StaticPageEdit },

  { path: "/Settings", component: Setting },
  { path: "/Settings/add", component: SettingEdit },
  { path: "/Settings/view/:id", component: SettingView },
  { path: "/Settings/edit/:id", component: SettingEdit },

  { path: "/tickets", component: Ticket },
  { path: "/tickets/add", component: TicketEdit },
  { path: "/tickets/view/:id", component: TicketView },
  { path: "/tickets/edit/:id", component: TicketEdit },

  { path: "/complimentary-tickets", component: ComplimentaryTickets },
  {
    path: "/complimentary-tickets/book/:id",
    component: BookComplimentaryTickets
  },
  {
    path: "/complimentary-tickets/view/:id",
    component: ComplimentaryTicketsView
  },

  { path: "/mail-template", component: MailTemplate },
  { path: "/mail-template/add", component: MailTemplateEdit },
  { path: "/mail-template/view/:id", component: MailTemplateView },
  { path: "/mail-template/edit/:id", component: MailTemplateEdit },

  { path: "/contact", component: Contact },
  { path: "/contact/view/:id", component: ContactView },

  { path: "/order", component: Order },
  { path: "/order/view/:id", component: OrderView },

  { path: "/order-transaction", component: OrderTransaction },
  { path: "/withdraw-transaction", component: WithdrawTransaction },
  { path: "/ratings", component: Rating },

  { path: "/ratings/view/:id", component: RatingView }
];

export default dashboardRoutes;
