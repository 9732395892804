import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const getOrderList = (page, limit, search, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/order/list`, {
      page,
      limit,
      search,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      return res.data;
    });
};

const getOrderDetail = order_id => {
  Headers.setHeaders();
  return axios.get(`${BASE_URL}/admin/v1/order/view/${order_id}`).then(res => {
    return res.data;
  });
};

export { getOrderList, getOrderDetail };
