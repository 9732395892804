import React from "react";
import EventWrapper from "./event.style";
import PageTitle from "components/common/PageTitle";
import EventTable from "components/events/EventTable";
import { connect } from "react-redux";

const Event = props => {
  return (
    <EventWrapper {...props}>
      <PageTitle
        title="sidebar.event"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.event"
          }
        ]}
      />
      <div className="user-tbl">
        <EventTable {...props} />
      </div>
    </EventWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Event);
