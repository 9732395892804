import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CustomToast from "components/notifications/CustomToast";
import LoaderComponent from "components/common/LoaderComponent";
import { getAllPages } from "../../api/page";
import AuthActions from "../../redux/auth/actions";
const { logout } = AuthActions;

const initSnackBar = {
  flag: false,
  heading: "",
  description: ""
};

const StaticPageTable = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [tblData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(50);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("createdAt");
  const [order, setOrder] = useState("desc");
  const tableRef = useRef();
  const { history } = props;

  const formAction = useCallback(
    (action, data = null) => {
      // Here you can view the data and make forward action for edit data
      if (action === "add") {
        history.push("/staticPages/add");
      } else if (action === "edit") {
        history.push(`/staticPages/edit/${data.id}`, { ...data });
      } else if (action === "view") {
        history.push(`/staticPages/view/${data.id}`, { ...data });
      }
    },
    [history]
  );

  useEffect(() => {
    setTable(activePage + 1, 10, field, order);
    // eslint-disable-next-line
  }, [activePage, field, order]);

  const setTable = (page, limit, field, order) => {
    setLoader(true);
    getAllPages(page, limit, field, order)
      .then(data => {
        const { rows, count } = data.data;
        setPages(Math.ceil(count / 10));
        setTableData(rows);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class sort-sign"
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        Cell: props => (
          <div className="react-action-class">
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", props.original)}
            >
              <div className="fs-14 medium-text" title="View">
                <i className="fas fa-eye" />
              </div>
            </Button>
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", props.original)}
            >
              <div className="fs-14 medium-text" title="Edit">
                <i className="fas fa-edit" />
              </div>
            </Button>
          </div>
        ) // Custom cell components!
      }
    ],
    [formAction]
  );

  const onChangePageGetData = state => {
    if (activePage !== null) {
      const sorted = state.sorted;
      if (sorted.length > 0) {
        setField(sorted[0].id);
        setOrder(sorted[0].desc ? "DESC" : "ASC");
      }
    }
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Page list
            </div>
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add")}
                style={{
                  backgroundColor: "#2193b0",
                  color: "#fff"
                }}
              >
                <i className="fas fa-plus mr-10"></i>Add Page
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              ref={tableRef}
              data={tblData}
              columns={columns}
              defaultPageSize={Math.min(tblData.length, 10)}
              filterable={false}
              manual
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              LoadingComponent={LoaderComponent}
              loading={loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => setActivePage(pageIndex)}
            />
          </div>
          <CustomToast
            heading={snackBar.heading}
            width={400}
            show={snackBar.flag}
            transition
            position="top-middle"
            className="c-success"
            message={snackBar.description}
            onCloseCLick={() => setSnackBar(initSnackBar)}
          />
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, connect(null, { logout }))(StaticPageTable);
