import React from "react";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ComplimentaryWrapper from "./complimentary.style";
import ComplimentaryTicketsTable from "components/complimentaryTickets/ComplimentaryTicketsTable";

const ComplimentaryTickets = props => {
  return (
    <ComplimentaryWrapper {...props}>
      <PageTitle
        title="sidebar.complimentary"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.complimentary"
          }
        ]}
      />
      <div className="user-tbl">
        <ComplimentaryTicketsTable {...props} />
      </div>
    </ComplimentaryWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(ComplimentaryTickets);
