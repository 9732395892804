import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const getTicketList = (page, limit, search, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/complimentaryticket/list`, {
      page,
      limit,
      search,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      return res.data;
    });
};

const getTickets = data => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/complimentaryticket/all-tickets`, data)
    .then(res => {
      return res.data;
    });
};

const getTicketDetails = data => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/complimentaryticket/ticket-details`, data)
    .then(res => {
      return res.data;
    });
};

const bookTickets = data => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/complimentaryticket/place-order`, data)
    .then(res => {
      return res.data;
    });
};

export { getTicketList, getTickets, getTicketDetails, bookTickets };
