import axios from "axios";
import { BASE_URL } from "../helper/global";

const resetPassword = credentails => {
  return axios
    .post(`${BASE_URL}/mobile/resetPassword`, credentails)
    .then(res => {
      return res.data;
    });
};

export { resetPassword };
