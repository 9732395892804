import styled from "styled-components";

const DashboardWrapper = styled.div`
  .Grid {
    display: flex;
    flex-direction: row;
  }
  .Card {
    margin: 0px 10px;
    max-width: 32%;
    flex: 1;
    background-color: #eb3349; /* fallback for old browsers */
    background: #0575e6; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #021b79,
      #0575e6
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #021b79,
      #0575e6
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    height: 110px;
    cursor: pointer;
  }
  .Card:hover {
    opacity: 0.9;
  }
  .font-style {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 100;
  }
  .font-style-2 {
    text-align: center !important;
    font-size: 30px;
    font-weight: 600;
    color: #fff;
  }
  .Card-2 {
    margin: 0px 10px;
    max-width: 32%;
    flex: 1;
    background-color: #00b4db; /* fallback for old browsers */
    background: #2193b0; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #6dd5ed,
      #2193b0
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #6dd5ed,
      #2193b0
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    height: 110px;
    cursor: pointer;
  }
  .Card-2:hover {
    opacity: 0.9;
  }
  .Card-3 {
    margin: 0px 10px;
    max-width: 32%;
    flex: 1;
    background: #f7971e; /* fallback for old browsers */
    background: #24c6dc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #514a9d,
      #24c6dc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #514a9d,
      #24c6dc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    height: 110px;
    cursor: pointer;
  }
  .Card-3:hover {
    opacity: 0.9;
  }
  .Card-4 {
    background: #1488cc; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-4:hover {
    opacity: 0.9;
  }
  .Card-5 {
    background: #cc2b5e; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-5:hover {
    opacity: 0.9;
  }
  .Card-6 {
    background: #02aab0; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-6:hover {
    opacity: 0.9;
  }
`;

export default DashboardWrapper;
