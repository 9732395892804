import React, { useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import { ProfileLockScreen } from "helper/constant";

const ReferralForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    loading,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const [uploadImage, setUploadImage] = useState(values.image || null);
  useEffect(() => {
    setUploadImage(values.image);
    if (values.image && typeof values.image === "object") {
      setUploadImage(URL.createObjectURL(values.image));
    }
  }, [values.image]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };
  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Profile Image</label>
          <br />
          <div className="file-upload">
            <label
              className="c-btn c-primary form-button fs-16 demi-bold-text mt-15"
              style={{ width: "203px" }}
            >
              Upload Image
              <input
                type="file"
                id="image"
                onChange={e => {
                  setFieldValue("image", e.target.files[0]);
                }}
                onBlur={handleBlur}
                placeholder="image"
                className="file-upload__input"
                name="file-upload"
                accept="image/jpg,image/png,image/jpeg"
              />
            </label>
          </div>
          {
            <img
              id="image"
              alt=""
              className="profile mtb-15"
              src={uploadImage || ProfileLockScreen}
              style={{
                width: "200px",
                borderRadius: "4%",
                height: "150px",
                background: "#404040"
              }}
            />
          }
          <br />
          <Error field="image" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">First Name</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="first_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
            placeholder="First Name"
          />
          <Error field="first_name" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Last Name</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="last_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
            placeholder="Last Name"
          />
          <Error field="last_name" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Email</label>
          <input
            type="email"
            readOnly={action === "edit" ? true : false}
            className="form-control react-form-input"
            id="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Email"
          />
          <Error field="email" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Phone Number</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="mobile"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobile}
            placeholder="Phone Number"
          />
          <Error field="mobile" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Introduction</label>
          <input
            type="textarea"
            className="form-control react-form-input"
            id="introduction"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.introduction}
            placeholder="Introduction"
          />
          <Error field="introduction" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Zip Code</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="zip_code"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.zip_code}
            placeholder="Zip Code"
          />
          <Error field="zip_code" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text mr-3">Is Approved? </label>
          <div className="form-check form-check-inline mt-7">
            <input
              className="form-check-input"
              type="radio"
              name="is_approved"
              id="is_approved"
              value="1"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={Number(values.is_approved) === 1 ? "checked" : false}
            />
            <label className="form-check-label">Approved</label>
          </div>
          <div className="form-check form-check-inline mt-7">
            <input
              className="form-check-input"
              type="radio"
              name="is_approved"
              id="is_approved"
              value="2"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={Number(values.is_approved) === 2 ? "checked" : false}
            />
            <label className="form-check-label">Rejected</label>
          </div>
          <Error field="is_approved" />
        </div>
        <div>
          <Button
            type="button"
            loading={loading}
            dataStyle="expand-left"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(ReferralForm);
