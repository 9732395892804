import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const getReviews = (page, limit, search, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/rating/list`, {
      page,
      limit,
      search,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      return res.data;
    });
};

export { getReviews };
