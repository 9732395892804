import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Delete from "components/common/Delete";
import Pagination from "components/common/Pagination";
import {
  getReferral,
  deleteReferral,
  statusChange,
  changeStatusApproved
} from "../../api/referral";
import "react-table/react-table.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CustomToast from "components/notifications/CustomToast";
import LoaderComponent from "components/common/LoaderComponent";
import AuthActions from "../../redux/auth/actions";
import { ProfileLockScreen } from "helper/constant";
const { logout } = AuthActions;

let debounceTimer;

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const ReferralTable = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [tblData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(50);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [changeMethodFlag] = useState(false);
  const tableRef = useRef();
  const [toggle, setToggle] = useState(false);
  const { history } = props;
  const [loading, setLoading] = useState(false);
  const [referral, setReferral] = useState(null);
  const [field, setField] = useState(null);
  const [order, setOrder] = useState(null);
  // eslint-disable-next-line
  const formAction = (action, data = null, param = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      history.push("/referrals/add");
    } else if (action === "edit") {
      history.push(`/referrals/edit/${data.id}`, { ...data });
    } else if (action === "view") {
      history.push(`/referrals/view/${data.id}`, { ...data });
    } else if (action === "remove") {
      remove(data.id);
    } else if (action === "status") {
      changeStatus(data.id);
    } else if (action === "approve") {
      changeApproved(data.id, param);
    } else if (action === "average-rating-views") {
      history.push(`/referrals/average-ratings/${data.id}`, { ...data });
    } else if (action === "average-rating-count") {
      history.push(`/referrals/average-ratings-count/${data.id}`, { ...data });
    }
  };

  useEffect(() => {
    setTable(activePage + 1, 10, searchText, field, order);
    // eslint-disable-next-line
  }, [searchText, activePage, field, order]);

  const setTable = (page, limit, search, field, order) => {
    setLoader(true);
    getReferral(page, limit, search, field, order)
      .then(data => {
        const { rows, count } = data.data;
        setPages(Math.ceil(count / 10));
        setTableData(rows);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
  };

  const changeApproved = (id, status) => {
    changeStatusApproved(id, status)
      .then(response => {
        setLoading(false);
        setToggle(false);
        setSnackBar({
          flag: true,
          className: "c-success",
          heading: "Delete Handler",
          description: response.message
        });
        setTable(activePage + 1, 10, searchText);
      })
      .catch(error => {
        setLoading(false);
        setToggle(false);
        setSnackBar({
          flag: true,
          className: "c-danger",
          heading: "Something went wrong",
          description: error.response.data.message
        });
        console.log(error);
      });
  };

  const changeStatus = id => {
    statusChange(id)
      .then(response => {
        setLoading(false);
        setToggle(false);
        setSnackBar({
          flag: true,
          className: "c-success",
          heading: "Delete Handler",
          description: response.message
        });
        setTable(activePage + 1, 10, searchText);
      })
      .catch(error => {
        setLoading(false);
        setToggle(false);
        setSnackBar({
          flag: true,
          className: "c-danger",
          heading: "Something went wrong",
          description: error.response.data.message
        });
        console.log(error);
      });
  };

  const remove = id => {
    setLoading(true);
    deleteReferral(id)
      .then(response => {
        setLoading(false);
        setToggle(false);
        setSnackBar({
          flag: true,
          className: "c-success",
          heading: "Delete Handler",
          description: response.message
        });
        setTable(activePage + 1, 10, searchText);
      })
      .catch(error => {
        setLoading(false);
        setToggle(false);
        setSnackBar({
          flag: true,
          className: "c-danger",
          heading: "Something went wrong",
          description: error.response.data.message
        });
        console.log(error);
      });
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Profile",
        accessor: "image",
        className: "text-center",
        width: 100,
        filterable: false,
        sortable: false,
        headerClassName: "react-table-header-class",
        Cell: props => (
          <div>
            <img
              src={props.original.image || ProfileLockScreen}
              alt="error"
              style={{ height: "30px", width: "30px", borderRadius: "50%" }}
            />
          </div>
        )
      },
      {
        Header: "First Name",
        accessor: "first_name",
        className: "text-center",
        filterable: false,
        width: 150,
        headerClassName: "react-table-header-class sort-sign"
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        className: "text-center",
        filterable: false,
        width: 150,
        headerClassName: "react-table-header-class sort-sign"
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-center",
        width: 250,
        filterable: false,
        headerClassName: "react-table-header-class sort-sign"
      },
      {
        Header: "Phone Number",
        accessor: "mobile",
        sortable: false,
        className: "text-center",
        filterable: false,
        width: 150,
        headerClassName: "react-table-header-class"
      },
      {
        Header: "Status",
        accessor: "is_active",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 130,
        filterable: false,
        Cell: props => {
          return !props.original.is_active ? (
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("status", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye-slash mr-6" /> InActive
              </div>
            </Button>
          ) : (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("status", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye mr-6" /> Active
              </div>
            </Button>
          );
        } // Custom cell components!
      },
      {
        Header: "Notification",
        accessor: "is_notification",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 100,
        filterable: false,
        Cell: props => {
          return !props.original.is_notification ? (
            <Button className="c-btn c-info mr-10">
              <div className="fs-14 medium-text">
                <i className="fas fa-toggle-off mr-6" /> Off
              </div>
            </Button>
          ) : (
            <Button className="c-btn c-success mr-10">
              <div className="fs-14 medium-text">
                <i className="fas fa-toggle-on mr-6" /> On
              </div>
            </Button>
          );
        } // Custom cell components!
      },

      {
        Header: "Average Rating",
        accessor: "avg_ratings",
        className: "text-center",
        headerClassName: "react-table-header-class sort-sign",
        sortable: true,
        width: 150,
        filterable: false,
        Cell: props => {
          return (
            props.original.avg_ratings !== null && (
              <Button
                className="c-btn c-info mr-10"
                onClick={() =>
                  formAction("average-rating-views", props.original)
                }
              >
                <div className="fs-14 medium-text">
                  {props.original.avg_ratings}
                </div>
              </Button>
            )
          );
        }
        // Custom cell components!
      },
      {
        Header: "Rating Count",
        accessor: "low_ratings",
        className: "text-center",
        headerClassName: "react-table-header-class sort-sign",
        sortable: true,
        width: 150,
        filterable: false,
        Cell: props => {
          return (
            props.original.low_ratings !== null && (
              <Button
                className="c-btn c-info mr-10"
                onClick={() =>
                  formAction("average-rating-count", props.original)
                }
              >
                <div className="fs-14 medium-text">
                  {props.original.low_ratings}
                </div>
              </Button>
            )
          );
        }
        // Custom cell components!
      },
      {
        Header: "Approve/Reject",
        accessor: "is_approved",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 180,
        filterable: false,
        Cell: props => {
          if (props.original.is_approved === 0) {
            return (
              <div>
                <Button
                  className="c-btn c-success mr-10"
                  onClick={() => formAction("approve", props.original, 1)}
                >
                  <div className="fs-14 medium-text">Approve</div>
                </Button>
                <Button
                  className="c-btn c-danger mr-10"
                  onClick={() => formAction("approve", props.original, 2)}
                >
                  <div className="fs-14 medium-text">Reject</div>
                </Button>
              </div>
            );
          } else if (props.original.is_approved === 1) {
            return (
              <Button
                className="c-btn c-danger mr-10"
                onClick={() => formAction("approve", props.original, 2)}
              >
                <div className="fs-14 medium-text">Reject</div>
              </Button>
            );
          } else {
            return (
              <Button
                className="c-btn c-success mr-10"
                onClick={() => formAction("approve", props.original, 1)}
              >
                <div className="fs-14 medium-text">Approve</div>
              </Button>
            );
          }
        } // Custom cell components!
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 200,
        filterable: false,
        Cell: props => (
          <div className="react-action-class">
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", props.original)}
            >
              <div className="fs-14 medium-text" title="View">
                <i className="fas fa-eye" />
              </div>
            </Button>
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", props.original)}
            >
              <div className="fs-14 medium-text" title="Edit">
                <i className="fas fa-edit" />
              </div>
            </Button>
            <Button
              className="c-btn c-danger"
              id="delete"
              onClick={() => {
                setReferral(props.original);
                setToggle(true);
              }}
            >
              <div className="fs-14 medium-text" title="Delete">
                <i className="fas fa-trash" />
              </div>
            </Button>
          </div>
        ) // Custom cell components!
      }
    ],
    [formAction]
  );

  useEffect(() => {
    if (searchText !== "") {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      // debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  const onChangePageGetData = state => {
    if (activePage !== null) {
      const sorted = state.sorted;
      if (sorted.length > 0) {
        setField(sorted[0].id);
        setOrder(sorted[0].desc ? "DESC" : "ASC");
      }
    }
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Referral list
            </div>
            <div className="mr-10">
              <input
                value={searchText ? searchText : ""}
                onChange={e => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            {
              <div>
                <Button
                  className="c-btn ma-5"
                  onClick={() => formAction("add")}
                  style={{
                    backgroundColor: "#2193b0",
                    color: "#fff"
                  }}
                >
                  <i className="fas fa-plus mr-10"></i>Add Referral
                </Button>
              </div>
            }
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              ref={tableRef}
              filterable={false}
              data={tblData}
              columns={columns}
              defaultPageSize={Math.min(tblData.length, 10)}
              manual
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              LoadingComponent={LoaderComponent}
              loading={loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => setActivePage(pageIndex)}
              changeMethodFlag={changeMethodFlag}
            />
          </div>
          <CustomToast
            heading={snackBar.heading}
            width={400}
            show={snackBar.flag}
            transition
            className={snackBar.className}
            position="top-middle"
            message={snackBar.description}
            onCloseCLick={() => setSnackBar(initSnackBar)}
          />
          <Delete
            toggle={toggle}
            setToggle={setToggle}
            handleYes={() => {
              formAction("remove", referral);
            }}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, connect(null, { logout }))(ReferralTable);
