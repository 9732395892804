import React, { useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { ProfileLockScreen } from "helper/constant";
import { getTicketDetail } from "../../api/ticket";
import moment from "moment";
import { Table } from "reactstrap";

const TicketViewModal = props => {
  const [ticketData, setTicketData] = useState({});

  useEffect(() => {
    getTicketData(props.ticket.id, props.ticket.ticket_type.title);
    // eslint-disable-next-line
  }, [props.ticket.id, props.ticket.ticket_type.title]);

  const getTicketData = (id, ticket_type) => {
    getTicketDetail(id, ticket_type)
      .then(data => {
        setTicketData(data.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Image&nbsp;:&nbsp;</label>
          <div id="profile">
            <img
              className="top-header-profile-class"
              style={{
                height: "125px",
                width: "125px",
                marginBottom: "15px",
                borderRadius: "10px",
                marginTop: "10px"
              }}
              src={ticketData.image || ProfileLockScreen}
              alt="notify"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Seating Map Image&nbsp;:&nbsp;
          </label>
          <div id="profile">
            <img
              className="top-header-profile-class"
              style={{
                height: "125px",
                width: "125px",
                marginBottom: "15px",
                borderRadius: "10px",
                marginTop: "10px"
              }}
              src={ticketData.seating_map_image || ProfileLockScreen}
              alt="notify"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Name EN :&nbsp;</label>
          {ticketData.name_en}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Name ES :&nbsp;</label>
          {ticketData.name_es}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Description EN :&nbsp;</label>
          {ticketData.description_en}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Description ES :&nbsp;</label>
          {ticketData.description_es}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Venue :&nbsp;</label>
          {ticketData.venue}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Ticket Type :&nbsp;</label>
          {ticketData.ticket_type ? ticketData.ticket_type.title : ""}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Ticket Transer Type : </label>
          {ticketData.ticket_transfer_type
            ? " " + ticketData.ticket_transfer_type
            : ""}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Ticket Information :&nbsp;
          </label>
          {ticketData.ticket_infos ? (
            <Table>
              <tbody>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Seats</th>
                </tr>
                {ticketData.ticket_infos.map((element, index) => {
                  return (
                    <tr key={element.id}>
                      <td>
                        {element.from_date === element.to_date
                          ? moment(element.from_date).format("YYYY-MM-DD")
                          : moment(element.from_date).format("YYYY-MM-DD") +
                            " - " +
                            moment(element.to_date).format("YYYY-MM-DD")}
                      </td>
                      <td>{element.ticket_time}</td>
                      <td>{element.price}</td>
                      <td>{element.quantity}</td>
                      <td>{element.seat}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : ticketData.ticket_levels ? (
            <ul>
              {ticketData.ticket_levels.map(levels => {
                return (
                  <>
                    <li key={levels.id}>
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Level :&nbsp;
                        </label>
                        {levels.title}
                      </div>
                    </li>
                    <ul>
                      {levels.ticket_sections.map(sections => {
                        return (
                          <>
                            <li key={sections.id}>
                              <div className="form-group">
                                <label className="fs-16 medium-text">
                                  Section :&nbsp;
                                </label>
                                {sections.title}
                              </div>
                            </li>
                            <ul>
                              {sections.ticket_rows.map(rows => {
                                return (
                                  <>
                                    <li key={rows.id}>
                                      <div className="form-group">
                                        <label className="fs-16 medium-text">
                                          Row :&nbsp;
                                        </label>
                                        {rows.title}
                                      </div>
                                    </li>

                                    <Table>
                                      <tbody>
                                        <tr>
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>Price</th>
                                          <th>Quantity</th>
                                          <th>Seats</th>
                                        </tr>
                                        {rows.ticket_infos.map(element => (
                                          <tr>
                                            <td>
                                              {element.from_date ===
                                              element.to_date
                                                ? moment(
                                                    element.from_date
                                                  ).format("YYYY-MM-DD")
                                                : moment(
                                                    element.from_date
                                                  ).format("YYYY-MM-DD") +
                                                  " - " +
                                                  moment(
                                                    element.to_date
                                                  ).format("YYYY-MM-DD")}
                                            </td>
                                            <td>{element.ticket_time}</td>
                                            <td>{element.price}</td>
                                            <td>{element.quantity}</td>
                                            <td>{element.seat}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </>
                                );
                              })}
                            </ul>
                          </>
                        );
                      })}
                    </ul>
                  </>
                );
              })}
            </ul>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(TicketViewModal);
