import React, { useState } from "react";
import { loginBack, ForgotIcon } from "helper/constant";
import CustomToast from "components/notifications/CustomToast";
import enhancer from "./enhancer/CreatePasswordEnhancer";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { createPassword } from "../../../api/admin";
import Button from "components/button/Button";

const initSnackBar = {
  flag: false,
  heading: "",
  description: "",
  className: "c-success"
};

const ResetPassword = props => {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [loading, setLoading] = useState(false);

  const handleLogin = (email, e) => {
    e.preventDefault();
    let { handleSubmit, values, isValid } = props;
    if (isValid) {
      setLoading(true);
      createPassword(email, values.password)
        .then(success => {
          setSnackBar({
            flag: true,
            heading: "Password Changed",
            className: "c-success",
            description: success.message
          });
        })
        .then(res => {
          setTimeout(() => {
            setLoading(true);
            props.history.push("/login");
          }, 2000);
        })
        .catch(error => {
          setLoading(true);
          setSnackBar({
            flag: true,
            className: "c-danger",
            heading: "Something went wrong",
            description: error.response.data.message
          });
        });
    }
    handleSubmit();
  };

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Reset Password ?</div>
        <div className="text-center form-info-text plr-24 mt-16">
          Reset Password
        </div>
        <form
          className="pa-24"
          onSubmit={e => {
            e.preventDefault();
            handleLogin(props.match.params.email, e);
          }}
        >
          <div className="form-group">
            <input
              type="password"
              className="form-control react-form-input"
              id="password"
              onChange={handleChange}
              value={values.password}
              onBlur={handleBlur}
              aria-describedby="emailHelp"
              placeholder="Enter password"
            />
            <Error field="password" />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control react-form-input"
              id="confirmPassword"
              onChange={handleChange}
              value={values.confirmPassword}
              onBlur={handleBlur}
              aria-describedby="emailHelp"
              placeholder="Confirm password"
            />
          </div>
          <Error field="confirmPassword" />
          <Button
            type="submit"
            dataStyle="expand-left"
            loading={loading}
            className="pos-relative btn form-button"
          >
            Reset Password
          </Button>
        </form>
      </div>
      <CustomToast
        heading={snackBar.heading}
        width={400}
        show={snackBar.flag}
        transition
        position="top-middle"
        className={snackBar.className}
        message={snackBar.description}
        onCloseCLick={() => setSnackBar(initSnackBar)}
      />
    </div>
  );
};

export default compose(withRouter, enhancer)(ResetPassword);
