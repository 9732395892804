import React, { Fragment, useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { Button, FormGroup, Label, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";

const PassTicket = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    loading,
    submitCount,
    onSubmit,
    values,
    action,
    handleInputInfoChange
  } = props;

  const [inputList, setInputList] = useState(
    values.ticket_infos || [
      {
        price: "",
        from_date: "",
        to_date: "",
        ticket_time: "",
        seat: "",
        quantity: "",
        end_seat_number: ""
      }
    ]
  );

  const handleInputChange = (e, index = 0) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setFieldValue("ticket_infos", list);
    handleInputInfoChange(list);
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  console.log("inputlist 61", inputList);
  console.log("values of ticketInfo", values.ticket_infos);

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-3">
          <FormGroup>
            <Label for="pirce">Price</Label>
            <Input
              type="number"
              name="price"
              id="price"
              placeholder="Price"
              onChange={e => handleInputChange(e)}
              onBlur={handleBlur}
              value={values.ticket_infos ? values.ticket_infos[0].price : ""}
            />
            <Error field="price" />
          </FormGroup>
        </div>
        <div className="col-md-3">
          <FormGroup>
            <Label for="date">From Date</Label>
            <Input
              type="date"
              name="from_date"
              id="from_date"
              placeholder="From Date"
              onChange={e => handleInputChange(e)}
              onBlur={handleBlur}
              value={
                values.ticket_infos
                  ? moment(values.ticket_infos[0].from_date).format(
                      "YYYY-MM-DD"
                    )
                  : ""
              }
            />
            <Error field="from_date" />
          </FormGroup>
        </div>
        <div className="col-md-3">
          <FormGroup>
            <Label for="to_date">To Date</Label>
            <Input
              type="date"
              name="to_date"
              id="to_date"
              placeholder="To Date"
              onChange={e => handleInputChange(e)}
              onBlur={handleBlur}
              value={
                values.ticket_infos
                  ? moment(values.ticket_infos[0].to_date).format("YYYY-MM-DD")
                  : ""
              }
            />
            <Error field="to_date" />
          </FormGroup>
        </div>
        <div className="col-md-3">
          <FormGroup>
            <Label for="ticket_time">Time</Label>
            <Input
              type="time"
              name="ticket_time"
              id="ticket_time"
              placeholder="Time"
              onChange={e => handleInputChange(e)}
              onBlur={handleBlur}
              value={
                values.ticket_infos ? values.ticket_infos[0].ticket_time : ""
              }
            />
            <Error field="ticket_time" />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormGroup>
            <Label for="seat">Seat</Label>
            <Input
              type="number"
              name="seat"
              id="seat"
              placeholder="Start Seat Number "
              onChange={e => handleInputChange(e)}
              onBlur={handleBlur}
              value={values.ticket_infos ? values.ticket_infos[0].seat : ""}
            />
            <Error field="seat" />
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup>
            <Label for="seat">End Seat Number</Label>
            <Input
              type="number"
              name="end_seat_number"
              id="end_seat_number"
              placeholder="End Seat Number"
              onChange={e => handleInputChange(e)}
              onBlur={handleBlur}
              value={
                values.ticket_infos
                  ? values.ticket_infos[0].end_seat_number
                  : ""
              }
            />
            <Error field="end_seat_number" />
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup>
            <Label for="quantity">Quantity</Label>
            <Input
              disabled
              type="number"
              name="quantity"
              id="quantity"
              placeholder="Quantity"
              onChange={e => handleInputChange(e)}
              onBlur={handleBlur}
              value={
                values.ticket_infos
                  ? Number(values.ticket_infos[0].end_seat_number) -
                    Number(values.ticket_infos[0].seat - 1)
                  : ""
              }
            />
            <Error field="quantity" />
          </FormGroup>
        </div>
      </div>
    </Fragment>
  );
};

export default compose(enhancer)(PassTicket);
