import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    oldPassword: Yup.string().required("Please Enter Password"),
    newPassword: Yup.string().required("Please Enter New Password"),
    confirmPassword: Yup.string().required("Please Enter Confirm Password")
  }),
  validate: values => {
    const errors = {};
    const pattern = new RegExp(
      "(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]"
    );
    if (values.newPassword) {
      if (values.newPassword.length < 8) {
        errors.newPassword = "password must have 8 characters";
      } else if (!pattern.test(values.newPassword)) {
        errors.newPassword = `password must have at-least one special character, alpha numeric letter and also one number`;
      }
    }

    if (
      values.confirmPassword &&
      values.newPassword !== values.confirmPassword
    ) {
      errors.confirmPassword = "password not matched";
    }
    return errors;
  },
  mapPropsToValues: props => ({
    password: "",
    confirmPassword: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
