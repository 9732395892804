import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import { getOrderList } from "../../api/order";
import "react-table/react-table.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CustomToast from "components/notifications/CustomToast";
import LoaderComponent from "components/common/LoaderComponent";
import AuthActions from "../../redux/auth/actions";
import { ProfileLockScreen } from "helper/constant";
const { logout } = AuthActions;

let debounceTimer;

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const OrderTable = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [tblData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(50);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [changeMethodFlag] = useState(false);
  const tableRef = useRef();
  const [toggle, setToggle] = useState(false);
  const { history } = props;
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);
  const [field, setField] = useState(null);
  const [order, setOrder] = useState(null);
  // eslint-disable-next-line
  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "view") {
      history.push(`/order/view/${data.id}`, { ...data });
    }
  };

  useEffect(() => {
    setTable(activePage + 1, 10, searchText, field, order);
    // eslint-disable-next-line
  }, [searchText, activePage, field, order]);

  const setTable = (page, limit, search, field, order) => {
    setLoader(true);
    getOrderList(page, limit, search, field, order)
      .then(data => {
        const { rows, count } = data.data;
        setPages(Math.ceil(count / 10));
        setTableData(rows);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "order_reference_id",
        className: "text-center",
        filterable: false,
        width: 175,
        headerClassName: "react-table-header-class sort-sign"
      },
      {
        Header: "First Name",
        accessor: "users.first_name",
        className: "text-center",
        filterable: false,
        width: 175,
        headerClassName: "react-table-header-class"
      },
      {
        Header: "Last Name",
        accessor: "users.last_name",
        className: "text-center",
        filterable: false,
        width: 175,
        headerClassName: "react-table-header-class"
      },
      {
        Header: "Email",
        accessor: "users.email",
        className: "text-center",
        filterable: false,
        width: 250,
        headerClassName: "react-table-header-class"
      },
      {
        Header: "Mobile Number",
        accessor: "users.mobile",
        className: "text-center",
        filterable: false,
        width: 250,
        headerClassName: "react-table-header-class"
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
        className: "text-center",
        filterable: false,
        width: 175,
        headerClassName: "react-table-header-class sort-sign"
      },
      {
        Header: "Order Date",
        accessor: "createdAt",
        className: "text-center",
        filterable: false,
        width: 175,
        headerClassName: "react-table-header-class sort-sign"
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 125,
        filterable: false,
        Cell: props => (
          <div className="react-action-class">
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", props.original)}
            >
              <div className="fs-14 medium-text" title="View">
                <i className="fas fa-eye" />
              </div>
            </Button>
          </div>
        ) // Custom cell components!
      }
    ],
    [formAction]
  );

  useEffect(() => {
    if (searchText !== "") {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      // debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  const onChangePageGetData = state => {
    if (activePage !== null) {
      const sorted = state.sorted;
      if (sorted.length > 0) {
        setField(sorted[0].id);
        setOrder(sorted[0].desc ? "DESC" : "ASC");
      }
    }
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Order list
            </div>
            <div className="mr-10">
              <input
                value={searchText ? searchText : ""}
                onChange={e => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              ref={tableRef}
              filterable={false}
              data={tblData}
              columns={columns}
              defaultPageSize={Math.min(tblData.length, 10)}
              manual
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              LoadingComponent={LoaderComponent}
              loading={loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => setActivePage(pageIndex)}
              changeMethodFlag={changeMethodFlag}
            />
          </div>
          <CustomToast
            heading={snackBar.heading}
            width={400}
            show={snackBar.flag}
            transition
            className={snackBar.className}
            position="top-middle"
            message={snackBar.description}
            onCloseCLick={() => setSnackBar(initSnackBar)}
          />
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, connect(null, { logout }))(OrderTable);
