import React from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";

const SettingForm = props => {
  console.log(props);
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    loading,
    onSubmit,
    values,
    action
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };
  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Key</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="key"
            readOnly={action === "edit" ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.key}
            placeholder="Key"
          />
          <Error field="key" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Value</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="value"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.value}
            placeholder="Value"
          />
          <Error field="value" />
        </div>

        <div>
          <Button
            type="button"
            loading={loading}
            dataStyle="expand-left"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(SettingForm);
