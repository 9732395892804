import React from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { ProfileLockScreen } from "helper/constant";

const EventViewModal = props => {
  const event = props.event;
  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Image&nbsp;:&nbsp;</label>
          <div id="profile">
            <img
              className="top-header-profile-class"
              style={{
                height: "125px",
                width: "125px",
                marginBottom: "15px",
                borderRadius: "10px",
                marginTop: "10px"
              }}
              src={event.image || ProfileLockScreen}
              alt="notify"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Name&nbsp;En&nbsp;:&nbsp;</label>
          {event.name_en}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Name&nbsp;Es&nbsp;:&nbsp;</label>
          {event.name_es}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Address&nbsp;:&nbsp;</label>
          {event.address}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Event &nbsp;:&nbsp;</label>
          {event.event_date}
        </div>
        {/* <div className="form-group">
          <label className="fs-16 medium-text">Latitude &nbsp;:&nbsp;</label>
          {event.latitude}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Longitude &nbsp;:&nbsp;</label>
          {event.longitude}
        </div> */}
      </form>
    </div>
  );
};

export default compose(enhancer)(EventViewModal);
