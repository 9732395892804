import React from "react";
import SettingWrapper from "./Setting.style";
import SettingTable from "components/settings/SettingTable";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";

const Setting = props => {
  return (
    <SettingWrapper {...props}>
      <PageTitle
        title="sidebar.settings"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.settings"
          }
        ]}
      />
      <div className="user-tbl">
        <SettingTable {...props} />
      </div>
    </SettingWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Setting);
