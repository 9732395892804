import React from "react";
import CustomerWrapper from "./customer.style";
import PageTitle from "components/common/PageTitle";
import CustomerTable from "components/customers/CustomerTable";
import { connect } from "react-redux";

const customer = props => {
  return (
    <CustomerWrapper {...props}>
      <PageTitle
        title="sidebar.customers"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.customers"
          }
        ]}
      />
      <div className="user-tbl">
        <CustomerTable {...props} />
      </div>
    </CustomerWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(customer);
