import React, { useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import "react-datepicker/dist/react-datepicker.css";
import { ProfileLockScreen } from "helper/constant";
import { allEvents, ticketTypeList } from "../../api/ticket";
import { Input } from "reactstrap";
import SingleDayTicket from "./SingleDayTicket";
import PassTicket from "./PassTicket";
import NormalTicket from "./NormalTicket";

const TicketForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    loading,
    submitCount,
    onSubmit,
    values,
    action
  } = props;
  const [uploadImage, setUploadImage] = useState(values.image || null);
  const [uploadMapImage, setUploadMapImage] = useState(
    values.seating_map_image || null
  );
  const [ticketTypes, setTicketTypes] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [dynamicView, setDynamicView] = useState();

  const ticketTypesNew = [
    {
      ticket_type_new_id: 1,
      ticket_transfer_type: "Digital"
    },
    {
      ticket_type_new_id: 2,
      ticket_transfer_type: "Physical"
    }
  ];

  useEffect(() => {
    setUploadImage(values.image);
    if (values.image && typeof values.image === "object") {
      setUploadImage(URL.createObjectURL(values.image));
    }
    setUploadMapImage(values.seating_map_image);
    if (
      values.seating_map_image &&
      typeof values.seating_map_image === "object"
    ) {
      setUploadMapImage(URL.createObjectURL(values.seating_map_image));
    }

    if (
      values.ticket_type_id &&
      (values.ticket_type_id == 1 || values.ticket_type_id == 3)
    ) {
      setDynamicView(
        <SingleDayTicket
          handleInputInfoChange={handleInputInfoChange}
          editedData={values}
          action={action}
        />
      );
    } else if (
      values.ticket_type_id &&
      (values.ticket_type_id == 2 || values.ticket_type_id == 4)
    ) {
      setDynamicView(
        <PassTicket
          handleInputInfoChange={handleInputInfoChange}
          editedData={values}
          action={action}
        />
      );
    } else if (values.ticket_type_id && values.ticket_type_id == 5) {
      setDynamicView(
        <NormalTicket
          handleInputInfoChange={handleInputInfoChange}
          editedData={values}
          action={action}
        />
      );
    }
  }, [values.image, values.seating_map_image, values.ticket_type_id]);

  useEffect(() => {
    getTicketType();
    getAllEvent();
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleInputInfoChange = (data, ticket_type = null) => {
    if (ticket_type == "normal") {
      //values.ticket_levels = data;
      setFieldValue("ticket_levels", data);
    } else {
      //values.ticket_infos = data;
      setFieldValue("ticket_infos", data);
    }
  };

  const getTicketType = () => {
    ticketTypeList()
      .then(data => {
        setTicketTypes(data.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
  };

  const getAllEvent = () => {
    allEvents()
      .then(data => {
        setEventList(data.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (values.action === "edit") {
      onSubmit(values, action);
    } else if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Image</label>
              <br />
              <div className="file-upload">
                <label
                  className="c-btn c-primary form-button fs-16 demi-bold-text mt-15"
                  style={{ width: "203px" }}
                >
                  Upload Image
                  <input
                    type="file"
                    id="image"
                    onChange={e => {
                      setFieldValue("image", e.target.files[0]);
                    }}
                    onBlur={handleBlur}
                    placeholder="image"
                    className="file-upload__input"
                    name="file-upload"
                    accept="image/jpg,image/png,image/jpeg"
                  />
                </label>
              </div>
              {
                <img
                  id="image"
                  alt=""
                  className="profile mtb-15"
                  src={uploadImage || ProfileLockScreen}
                  style={{
                    width: "200px",
                    borderRadius: "4%",
                    height: "150px",
                    background: "#404040"
                  }}
                />
              }
              <br />
              <Error field="image" />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Seating Map Image</label>
              <br />
              <div className="file-upload">
                <label
                  className="c-btn c-primary form-button fs-16 demi-bold-text mt-15"
                  style={{ width: "203px" }}
                >
                  Upload Seating Map Image
                  <input
                    type="file"
                    id="seating_map_image"
                    onChange={e => {
                      setFieldValue("seating_map_image", e.target.files[0]);
                    }}
                    onBlur={handleBlur}
                    placeholder="seating_map_image"
                    className="file-upload__input"
                    name="file-upload"
                    accept="image/jpg,image/png,image/jpeg"
                  />
                </label>
              </div>
              {
                <img
                  id="seating_map_image"
                  alt=""
                  className="profile mtb-15"
                  src={uploadMapImage || ProfileLockScreen}
                  style={{
                    width: "200px",
                    borderRadius: "4%",
                    height: "150px",
                    background: "#404040"
                  }}
                />
              }
              <br />
              <Error field="seating_map_image" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Name EN</label>
              <input
                type="text"
                className="form-control react-form-input"
                id="name_en"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name_en}
                placeholder="Name"
              />
              <Error field="name_en" />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Name ES</label>
              <input
                type="text"
                className="form-control react-form-input"
                id="name_es"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name_es}
                placeholder="Name"
              />
              <Error field="name_es" />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Venue</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="venue"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.venue}
            placeholder="Venue"
          />
          <Error field="venue" />
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Description EN</label>
              <textarea
                className="form-control react-form-input"
                id="description_en"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description_en}
                placeholder="Description EN"
              >
                {values.description_en}
              </textarea>
              <Error field="description_en" />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Description ES</label>
              <textarea
                className="form-control react-form-input"
                id="description_es"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description_es}
                placeholder="Description ES"
              >
                {values.description_es}
              </textarea>
              <Error field="description_es" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label className="fs-16 medium-text">Event</label>
              <Input
                type="select"
                name="event_id"
                id="event_id"
                onChange={handleChange}
                value={values.event_id}
                onBlur={handleBlur}
              >
                <option value="">Select Event</option>
                {eventList.map((event, i) => {
                  return (
                    <option key={`option${i}`} value={event.id}>
                      {event.name}
                    </option>
                  );
                })}
              </Input>
              <Error field="event_id" />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label className="fs-16 medium-text">Ticket Type</label>
              <Input
                type="select"
                name="ticket_type_id"
                id="ticket_type_id"
                onChange={handleChange}
                value={values.ticket_type_id}
                onBlur={handleBlur}
              >
                <option value="">Select ticket type</option>
                {ticketTypes.map((type, i) => {
                  return (
                    <option key={`option${i}`} value={type.id}>
                      {type.title}
                    </option>
                  );
                })}
              </Input>
              <Error field="ticket_type_id" />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label className="fs-16 medium-text">Ticket Transfer Type</label>
              <Input
                type="select"
                name="ticket_transfer_type"
                id="ticket_transfer_type"
                onChange={handleChange}
                value={values.ticket_transfer_type}
                onBlur={handleBlur}
              >
                <option value="">Select ticket transfer type</option>
                {ticketTypesNew.map((type, i) => {
                  return (
                    <option
                      key={`option${i}`}
                      value={type.ticket_transfer_type}
                    >
                      {type.ticket_transfer_type}
                    </option>
                  );
                })}
              </Input>
              {/* <Error field="ticket_transfer_type" /> */}
            </div>
          </div>
        </div>
        {dynamicView}
        <div>
          <Button
            type="button"
            loading={loading}
            dataStyle="expand-left"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(TicketForm);
