import React, { Fragment, useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { Button, FormGroup, Label, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";

const SingleDayTicket = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    values,
    action,
    handleInputInfoChange
  } = props;
  const [inputList, setInputList] = useState(
    values.ticket_infos || [
      {
        price: "",
        from_date: "",
        to_date: "",
        ticket_time: "",
        seat: "",
        quantity: "",
        end_seat_number: ""
      }
    ]
  );

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    handleInputInfoChange(list);
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        price: "",
        from_date: "",
        to_date: "",
        ticket_time: "",
        seat: "",
        quantity: ""
      }
    ]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (name === "from_date") {
      list[index]["to_date"] = value;
    }
    setInputList(list);
    handleInputInfoChange(list);
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  return (
    <Fragment>
      {inputList &&
        inputList.map((x, i) => {
          return (
            <fieldset className="fieldset" key={i}>
              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <Label for="price">Price</Label>
                    <Input
                      type="number"
                      name="price"
                      id="price"
                      placeholder="Price"
                      onChange={e => handleInputChange(e, i)}
                      onBlur={handleBlur}
                      value={x.price}
                    />
                    <Error field="price" />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <Label for="from_date">Date</Label>
                    <Input
                      type="date"
                      name="from_date"
                      id="from_date"
                      placeholder="Date"
                      onChange={e => handleInputChange(e, i)}
                      onBlur={handleBlur}
                      value={moment(x.from_date).format("YYYY-MM-DD")}
                    />
                    <Error field="from_date" />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <Label for="ticket_time">Time</Label>
                    <Input
                      type="time"
                      name="ticket_time"
                      id="ticket_time"
                      placeholder="with a placeholder"
                      onChange={e => handleInputChange(e, i)}
                      onBlur={handleBlur}
                      value={x.ticket_time}
                    />
                    <Error field="ticket_time" />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <Label for="seat">Start Seat Number</Label>
                    <Input
                      type="number"
                      name="seat"
                      id="seat"
                      placeholder="Start Seat Number"
                      onChange={e => handleInputChange(e, i)}
                      onBlur={handleBlur}
                      value={x.seat}
                    />
                    <Error field="seat" />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <Label for="seat">End Seat Number</Label>
                    <Input
                      type="number"
                      name="end_seat_number"
                      id="end_seat_number"
                      placeholder="End Seat Number"
                      onChange={e => handleInputChange(e, i)}
                      onBlur={handleBlur}
                      value={x.end_seat_number}
                    />
                    <Error field="end_seat_number" />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <Label for="quantity">Quantity</Label>
                    <Input
                      disabled
                      type="quantity"
                      name="quantity"
                      id="quantity"
                      placeholder="Quantity"
                      onChange={e => handleInputChange(e, i)}
                      onBlur={handleBlur}
                      value={x.end_seat_number - (x.seat - 1)}
                    />
                    <Error field="quantity" />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  {inputList.length !== 1 && (
                    <Button
                      type="button"
                      className="c-danger mt-30"
                      onClick={() => handleRemoveClick(i)}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </div>
            </fieldset>
          );
        })}
      <Button
        type="button"
        className="c-primary mb-10"
        onClick={handleAddClick}
      >
        Add More
      </Button>
    </Fragment>
  );
};

export default compose(enhancer)(SingleDayTicket);
