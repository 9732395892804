import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";
import FileDownload from "js-file-download";

const getOrderLogList = (page, limit, search, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/transaction/order-log`, {
      page,
      limit,
      search,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      return res.data;
    });
};

const exportOrderLog = () => {
  Headers.setHeaders();
  return axios
    .get(`${BASE_URL}/admin/v1/transaction/order-log-export`)
    .then(res => {
      FileDownload(res.data, "OrderTransactionLog.csv");
    });
};

const getWithdrawLogList = (page, limit, search, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/transaction/withdraw-log`, {
      page,
      limit,
      search,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      return res.data;
    });
};

const exportWithdrawLog = () => {
  Headers.setHeaders();
  return axios
    .get(`${BASE_URL}/admin/v1/transaction/withdraw-log-export`)
    .then(res => {
      FileDownload(res.data, "WithdrawTransactionLog.csv");
    });
};

export {
  getOrderLogList,
  exportOrderLog,
  getWithdrawLogList,
  exportWithdrawLog
};
