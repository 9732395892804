import React, { Fragment, useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { Button, FormGroup, Label, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";

const NormalTicket = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    loading,
    submitCount,
    onSubmit,
    values,
    action,
    handleInputInfoChange
  } = props;

  useEffect(() => {
    if (values.ticket_levels) {
      setInputList(values.ticket_levels);
    } else {
      setInputList([
        {
          title: "",
          ticket_sections: [
            {
              title: "",
              ticket_rows: [
                {
                  title: "",
                  ticket_infos: [
                    {
                      price: "",
                      to_date: "",
                      from_date: "",
                      ticket_time: "",
                      seat: "",
                      quantity: "",
                      end_seat_number: ""
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]);
    }
  }, [values.ticket_levels]);

  const [inputList, setInputList] = useState([]);

  const handleAddLevelClick = () => {
    setInputList([
      ...inputList,
      {
        title: "",
        ticket_sections: [
          {
            title: "",
            ticket_rows: [
              {
                title: "",
                ticket_infos: [
                  {
                    price: "",
                    to_date: "",
                    from_date: "",
                    ticket_time: "",
                    seat: "",
                    quantity: ""
                  }
                ]
              }
            ]
          }
        ]
      }
    ]);
  };

  const handleAddSectionClick = (e, i) => {
    const list = [...inputList];
    list[i]["ticket_sections"].push({
      title: "",
      ticket_rows: [
        {
          title: "",
          ticket_infos: [
            {
              price: "",
              to_date: "",
              from_date: "",
              ticket_time: "",
              seat: "",
              quantity: ""
            }
          ]
        }
      ]
    });
    setInputList(list);
  };

  const handleAddRowClick = (e, i, j) => {
    const list = [...inputList];
    list[i]["ticket_sections"][j]["ticket_rows"].push({
      title: "",
      ticket_infos: [
        {
          price: "",
          to_date: "",
          from_date: "",
          ticket_time: "",
          seat: "",
          quantity: ""
        }
      ]
    });
    setInputList(list);
  };

  const handleAddInfoClick = (e, i, j, k) => {
    const list = [...inputList];
    list[i]["ticket_sections"][j]["ticket_rows"][k]["ticket_infos"].push({
      price: "",
      to_date: "",
      from_date: "",
      ticket_time: "",
      seat: "",
      quantity: ""
    });
    setInputList(list);
  };

  const handleRemoveLevelClick = i => {
    const list = [...inputList];
    list.splice(i, 1);
    setInputList(list);
    handleInputInfoChange(list, "normal");
  };

  const handleRemoveSectionClick = (i, j) => {
    const list = [...inputList];
    list[i]["ticket_sections"].splice(j, 1);
    setInputList(list);
    handleInputInfoChange(list, "normal");
  };

  const handleRemoveRowClick = (i, j, k) => {
    const list = [...inputList];
    list[i]["ticket_sections"][j]["ticket_rows"].splice(k, 1);
    setInputList(list);
    handleInputInfoChange(list, "normal");
  };

  const handleRemoveInfoClick = (i, j, k, l) => {
    const list = [...inputList];
    list[i]["ticket_sections"][j]["ticket_rows"][k]["ticket_infos"].splice(
      l,
      1
    );
    setInputList(list);
    handleInputInfoChange(list, "normal");
  };

  const handleInputChange = (
    e,
    obj,
    i = null,
    j = null,
    k = null,
    l = null
  ) => {
    const { name, value } = e.target;
    const list = [...inputList];
    switch (obj) {
      case "ticket_level":
        list[i][name] = value;
        break;
      case "ticket_section":
        list[i]["ticket_sections"][j][name] = value;
        break;
      case "ticket_row":
        list[i]["ticket_sections"][j]["ticket_rows"][k][name] = value;
        break;
      case "ticket_info":
        if (name === "from_date") {
          list[i]["ticket_sections"][j]["ticket_rows"][k]["ticket_infos"][l][
            "to_date"
          ] = value;
        }
        list[i]["ticket_sections"][j]["ticket_rows"][k]["ticket_infos"][l][
          name
        ] = value;
        break;
    }
    setInputList(list);
    handleInputInfoChange(list, "normal");
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  return (
    <Fragment>
      {inputList &&
        inputList.map((levels, i) => {
          return (
            <>
              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <Label for="title">Level</Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      placeholder="Title"
                      onChange={e => handleInputChange(e, "ticket_level", i)}
                      onBlur={handleBlur}
                      value={levels.title}
                    />
                    <Error field="title" />
                  </FormGroup>
                </div>
              </div>
              <div>
                {levels.ticket_sections.map((sections, j) => {
                  return (
                    <>
                      <div className="row">
                        <div className="col-md-4">
                          <FormGroup>
                            <Label for="title">Section</Label>
                            <Input
                              type="text"
                              name="title"
                              id="title"
                              placeholder="Title"
                              onChange={e =>
                                handleInputChange(e, "ticket_section", i, j)
                              }
                              onBlur={handleBlur}
                              value={sections.title}
                            />
                            <Error field="title" />
                          </FormGroup>
                        </div>
                      </div>
                      <div>
                        {sections.ticket_rows.map((rows, k) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="title">Row</Label>
                                    <Input
                                      type="text"
                                      name="title"
                                      id="title"
                                      placeholder="Title"
                                      onBlur={handleBlur}
                                      value={rows.title}
                                      onChange={e =>
                                        handleInputChange(
                                          e,
                                          "ticket_row",
                                          i,
                                          j,
                                          k
                                        )
                                      }
                                    />
                                    <Error field="title" />
                                  </FormGroup>
                                </div>
                              </div>
                              <div>
                                {rows.ticket_infos.map((element, l) => (
                                  <fieldset className="fieldset">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <FormGroup>
                                          <Label for="price">Price</Label>
                                          <Input
                                            type="number"
                                            name="price"
                                            id="price"
                                            placeholder="Price"
                                            onChange={e =>
                                              handleInputChange(
                                                e,
                                                "ticket_info",
                                                i,
                                                j,
                                                k,
                                                l
                                              )
                                            }
                                            onBlur={handleBlur}
                                            value={element.price}
                                          />
                                          <Error field="price" />
                                        </FormGroup>
                                      </div>
                                      <div className="col-md-4">
                                        <FormGroup>
                                          <Label for="from_date">Date</Label>
                                          <Input
                                            type="date"
                                            name="from_date"
                                            id="from_date"
                                            placeholder="Date"
                                            onChange={e =>
                                              handleInputChange(
                                                e,
                                                "ticket_info",
                                                i,
                                                j,
                                                k,
                                                l
                                              )
                                            }
                                            onBlur={handleBlur}
                                            value={moment(
                                              element.from_date
                                            ).format("YYYY-MM-DD")}
                                          />
                                          <Error field="from_date" />
                                        </FormGroup>
                                      </div>
                                      <div className="col-md-4">
                                        <FormGroup>
                                          <Label for="ticket_time">Time</Label>
                                          <Input
                                            type="time"
                                            name="ticket_time"
                                            id="ticket_time"
                                            placeholder="with a placeholder"
                                            onChange={e =>
                                              handleInputChange(
                                                e,
                                                "ticket_info",
                                                i,
                                                j,
                                                k,
                                                l
                                              )
                                            }
                                            onBlur={handleBlur}
                                            value={element.ticket_time}
                                          />
                                          <Error field="ticket_time" />
                                        </FormGroup>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <FormGroup>
                                          <Label for="seat">Seat</Label>
                                          <Input
                                            type="number"
                                            name="seat"
                                            id="seat"
                                            placeholder="Start Seat Number"
                                            onChange={e =>
                                              handleInputChange(
                                                e,
                                                "ticket_info",
                                                i,
                                                j,
                                                k,
                                                l
                                              )
                                            }
                                            onBlur={handleBlur}
                                            value={element.seat}
                                          />
                                          <Error field="seat" />
                                        </FormGroup>
                                      </div>

                                      <div className="col-md-4">
                                        <FormGroup>
                                          <Label for="seat">Seat</Label>
                                          <Input
                                            type="number"
                                            name="end_seat_number"
                                            id="end_seat_number"
                                            placeholder="End Seat Number"
                                            onChange={e =>
                                              handleInputChange(
                                                e,
                                                "ticket_info",
                                                i,
                                                j,
                                                k,
                                                l
                                              )
                                            }
                                            onBlur={handleBlur}
                                            value={element.end_seat_number}
                                          />
                                          <Error field="seat" />
                                        </FormGroup>
                                      </div>
                                      <div className="col-md-4">
                                        <FormGroup>
                                          <Label for="quantity">Quantity</Label>
                                          <Input
                                            disabled
                                            type="number"
                                            name="quantity"
                                            id="quantity"
                                            placeholder="Quantity"
                                            onChange={e =>
                                              handleInputChange(
                                                e,
                                                "ticket_info",
                                                i,
                                                j,
                                                k,
                                                l
                                              )
                                            }
                                            onBlur={handleBlur}
                                            value={
                                              element.end_seat_number -
                                              (element.seat - 1)
                                            }
                                          />
                                          <Error field="quantity" />
                                        </FormGroup>
                                      </div>
                                      <div className="col-md-4">
                                        {rows.ticket_infos.length !== 1 && (
                                          <Button
                                            type="button"
                                            className="c-danger mt-30"
                                            onClick={() =>
                                              handleRemoveInfoClick(i, j, k, l)
                                            }
                                          >
                                            Remove
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  </fieldset>
                                ))}
                                <Button
                                  type="button"
                                  className="c-primary mb-10"
                                  onClick={e => handleAddInfoClick(e, i, j, k)}
                                >
                                  Add More Ticket Info
                                </Button>
                              </div>
                              <div>
                                {sections.ticket_rows.length !== 1 && (
                                  <div className="row">
                                    <div className="col-12 text-right">
                                      <Button
                                        type="button"
                                        className="c-danger mb-10"
                                        onClick={() =>
                                          handleRemoveRowClick(i, j, k)
                                        }
                                      >
                                        Remove Row
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                        <Button
                          type="button"
                          className="c-primary mb-10"
                          onClick={e => handleAddRowClick(e, i, j)}
                        >
                          Add More Row
                        </Button>
                      </div>
                      <div>
                        {levels.ticket_sections.length !== 1 && (
                          <div className="row">
                            <div className="col-12 text-right">
                              <Button
                                type="button"
                                className="c-danger mb-10"
                                onClick={() => handleRemoveSectionClick(i, j)}
                              >
                                Remove Section
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
                <Button
                  type="button"
                  className="c-primary mb-10"
                  onClick={e => handleAddSectionClick(e, i)}
                >
                  Add More Section
                </Button>
              </div>
              <div>
                {inputList.length !== 1 && (
                  <div className="row">
                    <div className="col-12 text-right">
                      <Button
                        type="button"
                        className="c-danger mb-10"
                        onClick={() => handleRemoveLevelClick(i)}
                      >
                        Remove Level
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </>
          );
        })}
      <Button
        type="button"
        className="c-primary mb-10"
        onClick={handleAddLevelClick}
      >
        Add More Level
      </Button>
    </Fragment>
  );
};

export default compose(enhancer)(NormalTicket);
