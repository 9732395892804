export const sidebarData = [
  {
    name: "sidebar.dashboard",
    routepath: "/dashboard",
    iconClass: "fas fa-chalkboard"
  },
  {
    name: "sidebar.admins",
    routepath: "/admins",
    iconClass: "fas fa-user-shield"
  },
  {
    name: "sidebar.customers",
    routepath: "/customers",
    iconClass: "fa fa-users"
  },
  {
    name: "sidebar.referrals",
    routepath: "/referrals",
    iconClass: "fa fa-users"
  },
  {
    name: "sidebar.events",
    routepath: "/events",
    iconClass: "fa fa-calendar"
  },
  {
    name: "sidebar.staticPage",
    routepath: "/staticPages",
    iconClass: "fa fa-clone"
  },
  {
    name: "sidebar.settings",
    routepath: "/settings",
    iconClass: "fa fa-cogs"
  },
  {
    name: "sidebar.tickets",
    routepath: "/tickets",
    iconClass: "fa fa-ticket-alt"
  },
  {
    name: "sidebar.order",
    routepath: "/order",
    iconClass: "fa fa-sort-amount-up"
  },
  {
    name: "sidebar.transaction",
    iconClass: "fa fa-coins",
    child: [
      {
        listname: "sidebar.order_transaction",
        routepath: "/order-transaction",
        shortname: "OT"
      },
      {
        listname: "sidebar.withdraw_transaction",
        routepath: "/withdraw-transaction",
        shortname: "WT"
      }
    ]
  },
  {
    name: "sidebar.mailTemplates",
    routepath: "/mail-template",
    iconClass: "fa fa-envelope"
  },
  {
    name: "sidebar.contact",
    routepath: "/contact",
    iconClass: "fa fa-list"
  },
  {
    name: "sidebar.ratingsTemplate",
    routepath: "/ratings",
    iconClass: "fa fa-star"
  },
  {
    name: "sidebar.offers",
    routepath: "/offers",
    iconClass: "fa fa-gift"
  },
  {
    name: "sidebar.transfer",
    routepath: "/transfer",
    iconClass: "fa fa-ticket-alt"
  },
  {
    name: "sidebar.complimentary",
    routepath: "/complimentary-tickets",
    iconClass: "fa fa-ticket-alt"
  }
];

export const HorizontalSidebarData = [
  {
    name: "sidebar.intro",
    routepath: "/Intro",
    iconClass: "fas fa-chalkboard"
  },
  {
    name: "sidebar.users",
    iconClass: "far fa-user",
    child: [
      {
        name: "sidebar.lists",
        routepath: "/users"
      }
    ]
  }
];
