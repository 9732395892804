import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const getAllTickets = () => {
  Headers.setHeaders();
  return axios.get(`${BASE_URL}/admin/v1/tickets/all-tickets`).then(res => {
    return res.data;
  });
};

const getOffersList = (page, limit, search, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/offers/list`, {
      page,
      limit,
      search,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      return res.data;
    });
};

const createOffer = data => {
  Headers.setHeaders();
  return axios.post(`${BASE_URL}/admin/v1/offers/add`, data).then(res => {
    return res.data;
  });
};

const deleteOffer = id => {
  Headers.setHeaders();
  return axios.delete(`${BASE_URL}/admin/v1/offers/remove/${id}`).then(res => {
    return res.data;
  });
};

const statusChange = id => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/offers/update-status/${id}`)
    .then(res => {
      return res.data;
    });
};

const updateOffers = (id, offers) => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/offers/update/${id}`, offers)
    .then(res => {
      return res.data;
    });
};

export {
  getAllTickets,
  getOffersList,
  createOffer,
  deleteOffer,
  statusChange,
  updateOffers
};
