import { store } from "../redux/store";
import axios from "axios";
class Headers {
  static setHeaders = () => {
    axios.defaults.headers.common[
      "access_token"
    ] = store.getState().auth.accessToken;
  };
}

export default Headers;
