import React from "react";
import AdminWrapper from "./admin.style";
import PageTitle from "components/common/PageTitle";
import AdminTable from "components/admins/AdminTable";
import { connect } from "react-redux";

const Admin = props => {
  return (
    <AdminWrapper {...props}>
      <PageTitle
        title="sidebar.admins"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.admins"
          }
        ]}
      />
      <div className="user-tbl">
        <AdminTable {...props} />
      </div>
    </AdminWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Admin);
