import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name_en: Yup.string().required("This field is required"),
    name_es: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required"),
    event_date: Yup.string().required("This field is required")
    //latitude: Yup.string().required("This field is required"),
    //longitude: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    action: props.action,
    image: props.editedData ? props.editedData.image : "",
    name_en: props.editedData ? props.editedData.name_en : "",
    name_es: props.editedData ? props.editedData.name_es : "",
    address: props.editedData ? props.editedData.address : "",
    event_date: props.editedData
      ? Date.parse(props.editedData.event_date)
      : new Date()
    //latitude: props.editedData ? props.editedData.latitude : "",
    //longitude: props.editedData ? props.editedData.longitude : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
