import React from "react";
import { Modal, ModalBody } from "reactstrap";
import Button from "components/button/Button";

const Delete = ({
  toggle,
  handleYes,
  className,
  setToggle,
  loading,
  message
}) => {
  return (
    <Modal centered isOpen={toggle} toggle={toggle} className={className}>
      <ModalBody>
        <div className="react-table-header-class text-center ptb-18">
          {message || "Are you sure you want to delete?"}
        </div>
        <div className="text-center pb-15">
          <Button
            className="c-btn c-success mr-10 wa-7"
            onClick={handleYes}
            loading={loading}
            dataStyle="expand-right"
          >
            Yes
          </Button>
          <Button
            className="c-btn c-danger wa-7"
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            No
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Delete;
