import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email id"),
    mobile: Yup.string().required("This field is required"),
    // role: Yup.string().required("This Field is required"),
    password: Yup.string().when("action", {
      is: "add",
      then: Yup.string().required("Please Enter Password")
    }),
    confirmPassword: Yup.string().when("action", {
      is: "add",
      then: Yup.string().required("Please Enter Confirm Password")
    })
  }),
  mapPropsToValues: props => ({
    action: props.action,
    image: props.editedData ? props.editedData.image : "",
    name: props.editedData ? props.editedData.name : "",
    email: props.editedData ? props.editedData.email : "",
    mobile: props.editedData ? props.editedData.mobile : "",
    role: props.editedData
      ? typeof props.editedData.role == "object"
        ? props.editedData.role.id
        : props.editedData.role
      : "",
    password: props.editedData ? props.editedData.password : "",
    confirmPassword: props.editedData ? props.editedData.confirmPassword : ""
  }),
  validate: values => {
    const errors = {};
    const pattern = new RegExp(
      "(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]"
    );
    if (values.password) {
      if (values.password.length < 8) {
        errors.password = "password must have 8 characters";
      } else if (!pattern.test(values.password)) {
        errors.password = `password must have at-least one special character, alpha numeric letter and also one number`;
      }
    }

    if (values.confirmPassword && values.password !== values.confirmPassword) {
      errors.confirmPassword = "password not matched";
    }
    return errors;
  },
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
