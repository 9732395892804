import React from "react";
import { compose } from "redux";
import enhancer from "./validator";

const StaticPageViewModal = props => {
  const { staticPage } = props;

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Title&nbsp;:&nbsp;</label>
          {staticPage.title}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Content EN&nbsp;:&nbsp;</label>
          <div
            className="container"
            dangerouslySetInnerHTML={{
              __html: staticPage.content_en || ""
            }}
          ></div>
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Content ES&nbsp;:&nbsp;</label>
          <div
            className="container"
            dangerouslySetInnerHTML={{
              __html: staticPage.content_es || ""
            }}
          ></div>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(StaticPageViewModal);
