import React, { Fragment, useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { Input } from "reactstrap";
import Button from "../button/Button";
import { ProfileLockScreen } from "helper/constant";

const AdminForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    loading,
    types,
    setFieldValue,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const [uploadImage, setUploadImage] = useState(values.image || null);
  useEffect(() => {
    setUploadImage(values.image);
    if (values.image && typeof values.image === "object") {
      setUploadImage(URL.createObjectURL(values.image));
    }
  }, [values.image]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Profile Image</label>
          <br />
          <div className="file-upload">
            <label
              className="c-btn c-primary form-button fs-16 demi-bold-text mt-15"
              style={{ width: "203px" }}
            >
              Upload Image
              <input
                type="file"
                id="image"
                onChange={e => {
                  setFieldValue("image", e.target.files[0]);
                }}
                onBlur={handleBlur}
                placeholder="image"
                className="file-upload__input"
                name="file-upload"
                accept="image/jpg,image/png,image/jpeg"
              />
            </label>
          </div>
          {
            <img
              id="image"
              alt=""
              className="profile mtb-15"
              src={uploadImage || ProfileLockScreen}
              style={{
                width: "200px",
                borderRadius: "4%",
                height: "150px",
                background: "#404040"
              }}
            />
          }
          <br />
          <Error field="image" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Name</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Name"
          />
          <Error field="name" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Email</label>
          <input
            type="email"
            readOnly={action === "edit" ? true : false}
            className="form-control react-form-input"
            id="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Email"
          />
          <Error field="email" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Phone Number</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="mobile"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobile}
            placeholder="Phone Number"
          />
          <Error field="mobile" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Admin Type<span className="asterisk">*</span>
          </label>
          <Input
            type="select"
            name=""
            id="role"
            onChange={handleChange}
            value={values.role}
            onBlur={handleBlur}
          >
            <option value="-1" id="-1">
              Select AdminType
            </option>
            {types.map((type, i) => {
              return (
                <Fragment>
                  <option key={`option${i}`} value={type.id}>
                    {type.role}
                  </option>
                </Fragment>
              );
            })}
          </Input>
          <Error field="role" />
        </div>
        {action === "add" && (
          <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Password</label>
              <input
                type="password"
                className="form-control react-form-input"
                id="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                placeholder="Password"
              />
              <Error field="password" />
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Confirm Password</label>
              <input
                type="password"
                className="form-control react-form-input"
                id="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                placeholder="Confirm Password"
              />
              <Error field="confirmPassword" />
            </div>
          </div>
        )}
        <div>
          <Button
            type="button"
            loading={loading}
            dataStyle="expand-left"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(AdminForm);
