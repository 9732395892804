import React from "react";
import { compose } from "redux";
import { ProfileLockScreen } from "helper/constant";

const ContactViewModal = props => {
  const contact = props.event;
  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            First&nbsp;Name&nbsp;:&nbsp;
          </label>
          {contact.first_name}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Last&nbsp;Name&nbsp;:&nbsp;
          </label>
          {contact.last_name}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">User Type&nbsp;:&nbsp;</label>
          {contact.user_type}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Subject &nbsp;:&nbsp;</label>
          {contact.subject}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Message &nbsp;:&nbsp;</label>
          {contact.message}
        </div>
      </form>
    </div>
  );
};

export default compose()(ContactViewModal);
