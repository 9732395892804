import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Delete from "components/common/Delete";
import Pagination from "components/common/Pagination";
import { getAdmins, deleteAdmin } from "../../api/admin";
import "react-table/react-table.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CustomToast from "components/notifications/CustomToast";
import LoaderComponent from "components/common/LoaderComponent";
import AuthActions from "../../redux/auth/actions";
import { ProfileLockScreen } from "helper/constant";
const { logout } = AuthActions;

let debounceTimer;

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const AdminTable = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [tblData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(50);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [changeMethodFlag] = useState(false);
  const tableRef = useRef();
  const [toggle, setToggle] = useState(false);
  const { history } = props;
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [field, setField] = useState("createdAt");
  const [order, setOrder] = useState("desc");

  // eslint-disable-next-line
  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      history.push("/admins/add");
    } else if (action === "edit") {
      history.push(`/admins/edit/${data.id}`, { ...data });
    } else if (action === "view") {
      history.push(`/admins/view/${data.id}`, { ...data });
    } else if (action === "remove") {
      remove(data.id);
    }
  };

  useEffect(() => {
    setTable(activePage + 1, 10, searchText, field, order);
    // eslint-disable-next-line
  }, [searchText, activePage, field, order]);

  const setTable = (page, limit, search, field, order) => {
    setLoader(true);
    getAdmins(page, limit, search, field, order)
      .then(data => {
        const { rows, count } = data.data;
        setPages(Math.ceil(count / 10));
        setTableData(rows);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
  };

  const remove = id => {
    setLoading(true);
    deleteAdmin(id)
      .then(response => {
        setLoading(false);
        setToggle(false);
        setSnackBar({
          flag: true,
          className: "c-success",
          heading: "Delete Handler",
          description: response.message
        });
        setTable(activePage + 1, 10, searchText);
      })
      .catch(error => {
        setLoading(false);
        setToggle(false);
        setSnackBar({
          flag: true,
          className: "c-danger",
          heading: "Something went wrong",
          description: error.response.data.message
        });
        console.log(error);
      });
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Profile",
        accessor: "image",
        className: "text-center",
        headerClassName: "react-table-header-class",
        filterable: false,
        sortable: false,
        width: 100,
        Cell: props => (
          <div>
            <img
              src={props.original.image || ProfileLockScreen}
              alt="error"
              style={{ height: "30px", width: "30px", borderRadius: "50%" }}
            />
          </div>
        )
      },
      {
        Header: "Name",
        accessor: "name",
        className: "text-center",
        filterable: false,
        width: 150,
        headerClassName: "react-table-header-class sort-sign"
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-center",
        filterable: false,
        width: 250,
        headerClassName: "react-table-header-class sort-sign"
      },
      {
        Header: "Phone Number",
        accessor: "mobile",
        className: "text-center",
        sortable: false,
        filterable: false,
        width: 200,
        headerClassName: "react-table-header-class"
      },
      {
        Header: "Admin Role",
        accessor: "admin_role.role",
        className: "text-center",
        sortable: false,
        filterable: false,
        width: 200,
        headerClassName: "react-table-header-class"
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        width: 200,
        Cell: props => (
          <div className="react-action-class">
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", props.original)}
            >
              <div className="fs-14 medium-text" title="View">
                <i className="fas fa-eye" />
              </div>
            </Button>
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", props.original)}
            >
              <div className="fs-14 medium-text" title="Edit">
                <i className="fas fa-edit" />
              </div>
            </Button>
            <Button
              className="c-btn c-danger"
              id="delete"
              onClick={() => {
                setAdmin(props.original);
                setToggle(true);
              }}
            >
              <div className="fs-14 medium-text" title="Delete">
                <i className="fas fa-trash" />
              </div>
            </Button>
          </div>
        ) // Custom cell components!
      }
    ],
    [formAction]
  );

  useEffect(() => {
    if (searchText !== "") {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      // debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  const onChangePageGetData = state => {
    if (activePage !== null) {
      const sorted = state.sorted;
      if (sorted.length > 0) {
        setField(sorted[0].id);
        setOrder(sorted[0].desc ? "DESC" : "ASC");
      }
    }
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Admin list
            </div>
            <div className="mr-10">
              <input
                value={searchText ? searchText : ""}
                onChange={e => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add")}
                style={{
                  backgroundColor: "#2193b0",
                  color: "#fff"
                }}
              >
                <i className="fas fa-plus mr-10"></i>Add Admin
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              filterable={false}
              ref={tableRef}
              data={tblData}
              columns={columns}
              defaultPageSize={Math.min(tblData.length, 10)}
              manual
              className="-striped -highlight custom-react-table-theme-class "
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              LoadingComponent={LoaderComponent}
              loading={loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => setActivePage(pageIndex)}
              changeMethodFlag={changeMethodFlag}
            />
          </div>
          <CustomToast
            heading={snackBar.heading}
            width={400}
            show={snackBar.flag}
            transition
            className={snackBar.className}
            position="top-middle"
            message={snackBar.description}
            onCloseCLick={() => setSnackBar(initSnackBar)}
          />
          <Delete
            toggle={toggle}
            setToggle={setToggle}
            handleYes={() => {
              formAction("remove", admin);
            }}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, connect(null, { logout }))(AdminTable);
