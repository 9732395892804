import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { ProfileLockScreen } from "helper/constant";
import { getOrderDetail } from "../../api/order";
import AuthActions from "../../redux/auth/actions";
const { logout } = AuthActions;

const OrderViewModal = props => {
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    getOrderData(props.order.id);
    // eslint-disable-next-line
  }, [props.order.id]);

  const getOrderData = id => {
    getOrderDetail(id)
      .then(data => {
        setOrderData(data.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Order Id :&nbsp;</label>
              {orderData.order_reference_id}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Customer Name :&nbsp;</label>
              {orderData.users
                ? orderData.users.first_name + " " + orderData.users.last_name
                : ""}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Email :&nbsp;</label>
              {orderData.users ? orderData.users.email : ""}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Mobile Number :&nbsp;</label>
              {orderData.users ? orderData.users.mobile : ""}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Total Amount :&nbsp;</label>
              {orderData.total_amount}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="fs-16 medium-text">Order Date :&nbsp;</label>
              {orderData.created_at}
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="fs-18 bold-text"> Ticket Information</div>
        </div>
        {orderData.order_tickets
          ? orderData.order_tickets.map((item, index) => {
              return (
                <div>
                  <hr></hr>
                  <div className="form-group">
                    <label className="fs-16 medium-text">
                      Ticket {index + 1} :{" "}
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Event Name :&nbsp;
                        </label>
                        {item.event.name_en}
                      </div>
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Ticket Name :&nbsp;
                        </label>
                        {item.ticket.name_en}
                      </div>
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Description :&nbsp;
                        </label>
                        {item.ticket.description_en}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Image&nbsp;:&nbsp;
                        </label>
                        <div id="profile">
                          <img
                            className="top-header-profile-class"
                            style={{
                              height: "125px",
                              width: "125px",
                              marginBottom: "15px",
                              borderRadius: "10px",
                              marginTop: "10px"
                            }}
                            src={item.ticket.image || ProfileLockScreen}
                            alt="notify"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Seating Map Image&nbsp;:&nbsp;
                        </label>
                        <div id="profile">
                          <img
                            className="top-header-profile-class"
                            style={{
                              height: "125px",
                              width: "125px",
                              marginBottom: "15px",
                              borderRadius: "10px",
                              marginTop: "10px"
                            }}
                            src={
                              item.ticket.seating_map_image || ProfileLockScreen
                            }
                            alt="notify"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Venue :&nbsp;
                        </label>
                        {item.ticket.venue}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Ticket Type :&nbsp;
                        </label>
                        {item.ticket.ticket_type.title}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Quantity :&nbsp;
                        </label>
                        {item.quantity}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Price Per Ticket :&nbsp;
                        </label>
                        {item.price_per_ticket}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Total Amount :&nbsp;
                        </label>
                        {item.ticket_total_amount}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          From Date :&nbsp;
                        </label>
                        {item.ticket_info.from_date}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          To Date :&nbsp;
                        </label>
                        {item.ticket_info.to_date}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fs-16 medium-text">
                          Ticket Time :&nbsp;
                        </label>
                        {item.ticket_info.ticket_time}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </form>
    </div>
  );
};

export default compose()(OrderViewModal);
