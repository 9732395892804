import React, { useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import { Input } from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import { getAllTickets } from "../../api/offers";
import Datepicker from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";
import AuthActions from "../../redux/auth/actions";

const optionsNew = [];

const { logout } = AuthActions;

const OfferForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    loading,
    submitCount,
    onSubmit,
    values,
    action,
    setFieldTouched
  } = props;
  const [selected, setSelected] = useState([]);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [offerType, setOfferType] = useState("");

  useEffect(() => {
    getAllTickets()
      .then(res => {
        res.data.map(values => {
          optionsNew.push({
            label: values.name,
            value: values.id
          });
        });
        setTicketOptions(optionsNew);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
  }, []);

  useEffect(() => {
    setFieldValue("ticket_ids", selected);
  }, [selected]);

  useEffect(() => {
    if (props.editedData) {
      const newTickets = props.editedData.offer_ticket.map(ticketData => {
        return {
          label: ticketData.ticket.name_en,
          value: ticketData.ticket_id
        };
      });
      setSelected(newTickets);
    }
  }, [props.editedData]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (values.action === "edit" && isValid) {
      onSubmit(values, action);
    } else if (values.action === "add" && isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };
  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Offer Name</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Offer Name"
          />
          <Error field="name" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Offer Type</label>
          <Input
            type="select"
            name="type"
            id="type"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.type}
          >
            <option value="">Select Type</option>
            <option value="Bonus">Bonus</option>
            <option value="Complimentary">Complimentary</option>
            <option value="Timeframe">Timeframe</option>
          </Input>
          <Error field="type" />
        </div>
        {ticketOptions && ticketOptions.length ? (
          <div className="form-group">
            <label className="fs-16 medium-text">Ticket</label>
            <MultiSelect
              options={ticketOptions}
              value={selected}
              onChange={setSelected}
              labelledBy="Select Tickets"
            />
            <Error field="ticket_ids" />
          </div>
        ) : (
          ""
        )}
        {values.type === "Bonus" || values.type === "Complimentary" ? (
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="fs-16 medium-text">Start Date</label>
                <div className="start-date">
                  <Datepicker
                    className="form-control"
                    selected={values.start_date}
                    id="start_date"
                    onChange={e => {
                      setFieldValue("start_date", e);
                    }}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Start Date"
                    //startDate = {new Date()}
                    minDate={new Date()}
                  />
                </div>
                <Error field="start_date" />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="fs-16 medium-text">End Date</label>
                <div className="end-date">
                  <Datepicker
                    className="form-control"
                    selected={values.end_date}
                    id="end_date"
                    onChange={e => {
                      setFieldValue("end_date", e);
                    }}
                    dateFormat="yyyy-MM-dd"
                    //dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    placeholderText="End Date"
                  />
                </div>
                <Error field="end_date" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {values.type === "Bonus" ? (
          <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Ticket Sell Quantity</label>
              <input
                type="number"
                min="1"
                className="form-control react-form-input"
                id="number_of_tickets"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.number_of_tickets}
                placeholder="Ticket Quantity"
              />
              <Error field="number_of_tickets" />
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Bonus Amount</label>
              <input
                type="number"
                min="1"
                className="form-control react-form-input"
                id="bonus_amount"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bonus_amount}
                placeholder="Bonus Amount"
              />
              <Error field="bonus_amount" />
            </div>
          </div>
        ) : values.type === "Complimentary" ? (
          <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Ticket Sell Quantity</label>
              <input
                type="number"
                min="1"
                className="form-control react-form-input"
                id="number_of_tickets"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.number_of_tickets}
                placeholder="Ticket Quantity"
              />
              <Error field="number_of_tickets" />
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Complimentary Tickets</label>
              <input
                type="number"
                min="1"
                className="form-control react-form-input"
                id="complimentary_ticket"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.complimentary_ticket}
                placeholder="Complimentary Tickets"
              />
              <Error field="complimentary_ticket" />
            </div>
          </div>
        ) : values.type === "Timeframe" ? (
          <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Ticket Frame Days</label>
              <input
                type="number"
                min="1"
                className="form-control react-form-input"
                id="timeframe"
                onChange={e => {
                  setFieldValue("timeframe", e.target.value);
                  if (values.start_date !== "") {
                    setFieldValue(
                      "end_date",
                      new Date(
                        new Date(values.start_date).getTime() +
                          e.target.value * 24 * 60 * 60 * 1000
                      )
                    );
                  }
                }}
                onBlur={handleBlur}
                value={values.timeframe}
                placeholder="Ticket Frame Days"
              />
              <Error field="timeframe" />
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label className="fs-16 medium-text">Start Date</label>
                  <div className="start-date">
                    <Datepicker
                      className="form-control"
                      selected={values.start_date}
                      id="start_date"
                      onChange={e => {
                        let newDate = moment(e).format("YYYY-MM-DD");
                        setFieldValue("start_date", e);
                        if (values.timeframe !== "") {
                          setFieldValue(
                            "end_date",
                            new Date(
                              new Date(e).getTime() +
                                values.timeframe * 24 * 60 * 60 * 1000
                            )
                          );
                        }
                      }}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Start Date"
                      //startDate = {new Date()}
                      disabled={values.timeFrame === "" ? true : false}
                      minDate={new Date()}
                    />
                  </div>
                  <Error field="start_date" />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="fs-16 medium-text">End Date</label>
                  <div className="end-date">
                    <Datepicker
                      className="form-control"
                      selected={values.end_date}
                      id="end_date"
                      onChange={e => {
                        setFieldValue("end_date", e);
                      }}
                      placeholderText="End Date"
                      disabled
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                  <Error field="end_date" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">
                Generate Total Revenue{" "}
              </label>
              <input
                type="number"
                min="1"
                className="form-control react-form-input"
                id="revenue_sales"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.revenue_sales}
                placeholder="Generate Revenue Sales"
              />
              <Error field="revenue_sales" />
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Bonus Amount</label>
              <input
                type="number"
                min="1"
                className="form-control react-form-input"
                id="bonus_amount"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bonus_amount}
                placeholder="Bonus Amount"
              />
              <Error field="bonus_amount" />
            </div>
          </div>
        ) : (
          ""
        )}

        <div>
          <Button
            type="button"
            loading={loading}
            dataStyle="expand-left"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer, connect(null, { logout }))(OfferForm);
