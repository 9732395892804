import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const deleteEvents = id => {
  Headers.setHeaders();
  return axios.delete(`${BASE_URL}/admin/v1/events/remove/${id}`).then(res => {
    return res.data;
  });
};

const createEvent = event => {
  Headers.setHeaders();
  return axios.post(`${BASE_URL}/admin/v1/events/add`, event).then(res => {
    return res.data;
  });
};

const statusChange = id => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/events/update-status/${id}`)
    .then(res => {
      return res.data;
    });
};

const updateEventDetails = (id, customer) => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/events/update/${id}`, customer)
    .then(res => {
      return res.data;
    });
};

const getEvents = (page, limit, search, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/events/list`, {
      page,
      limit,
      search,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      console.log("res event is", res);
      return res.data;
    });
};

const notifyUser = id => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/events/send-notification`, id)
    .then(res => {
      return res.data;
    });
};

export {
  deleteEvents,
  createEvent,
  statusChange,
  updateEventDetails,
  getEvents,
  notifyUser
};
