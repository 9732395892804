import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const createSetting = setting => {
  Headers.setHeaders();
  return axios.post(`${BASE_URL}/admin/v1/settings/add`, setting).then(res => {
    return res.data;
  });
};

const saveSetting = (id, setting) => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/settings/update/${id}`, setting)
    .then(res => {
      return res.data;
    });
};

const getAllSettings = (setting, limit, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/settings/list/`, {
      setting,
      limit,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      return res.data;
    });
};

export { getAllSettings, saveSetting, createSetting };
