import React from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { ProfileLockScreen } from "helper/constant";
import moment from "moment";

const OfferViewModal = props => {
  const offers = props.offers;

  return (
    <div>
      <form>
        {offers.type === "Bonus" ? (
          <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Offer Name:</label>
              {offers.name}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Offer Type:</label>
              {offers.type}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Number of Tickets:</label>
              {offers.number_of_tickets !== null
                ? offers.number_of_tickets
                : ""}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Bonus Amount:</label>
              {offers.bonus_amount !== null ? offers.bonus_amount : ""}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Offer Tickets:</label>
              {offers.offer_ticket &&
                offers.offer_ticket.map(tickets => {
                  return <div> {tickets.ticket.name_en}</div>;
                })}
            </div>

            <div className="form-group">
              <label className="fs-16 medium-text">Start Date:</label>
              {offers.start_date}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">End Date:</label>
              {offers.end_date}
            </div>
          </div>
        ) : offers.type === "Complimentary" ? (
          <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Offer Name:</label>
              {offers.name}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Offer Type:</label>
              {offers.type}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Number of Tickets:</label>
              {offers.number_of_tickets !== null
                ? offers.number_of_tickets
                : ""}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">
                Complimentary Tickets:
              </label>
              {offers.complimentary_ticket !== null
                ? offers.complimentary_ticket
                : ""}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Offer Tickets:</label>
              {offers.offer_ticket &&
                offers.offer_ticket.map(tickets => {
                  return <div> {tickets.ticket.name_en}</div>;
                })}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Start Date:</label>
              {offers.start_date}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">End Date:</label>
              {offers.end_date}
            </div>
          </div>
        ) : (
          <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Offer Name:</label>
              {offers.name}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Offer Type:</label>
              {offers.type}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Offer Tickets:</label>
              {offers.offer_ticket &&
                offers.offer_ticket.map(tickets => {
                  return <div> {tickets.ticket.name_en}</div>;
                })}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">TimeFrame:</label>
              {offers.timeframe !== null ? offers.timeframe : ""}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Start Date:</label>
              {offers.start_date}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">End Date:</label>
              {offers.end_date}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">
                Generate Total Revenue:{" "}
              </label>
              {offers.revenue_sales !== null ? offers.revenue_sales : ""}
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Bonus Amount:</label>
              {offers.bonus_amount !== null ? offers.bonus_amount : ""}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default compose(enhancer)(OfferViewModal);
