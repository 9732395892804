import React, { useState } from "react";
import { loginBack, iconDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import AuthActions from "redux/auth/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import Button from "components/button/Button";
import { login as authLogin } from "../../../api/admin";
import CustomToast from "components/notifications/CustomToast";

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};
const { login } = AuthActions;

const Login = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [loading, setLoading] = useState(false);
  const handleLogin = e => {
    e.preventDefault();
    let { values, handleSubmit } = props;

    if (values.username !== "" && values.password !== "") {
      setLoading(true);

      authLogin({ username: values.username, password: values.password })
        .then(success => {
          setSnackBar({
            flag: true,
            heading: "Admin LoggedIn !",
            className: "c-success",
            description: success.message
          });

          return success;
        })
        .then(response => {
          setTimeout(() => {
            const data = {
              token:
                response.data.adminDetails &&
                response.data.adminDetails.access_token,
              admin: response.data.adminDetails
            };
            setLoading(false);
            props.login(data);
            props.history.push("/dashboard");
          }, 1000);
        })
        .catch(error => {
          setLoading(false);
          if (error && error.response) {
            setSnackBar({
              flag: true,
              className: "c-danger",
              description: error.response.data.message
            });
          }
        });
    }
    handleSubmit();
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={iconDemo} alt="icon" height="100px" />
        </div>
        <div className="login-title">Sign in to your account</div>
        <form className="pa-24" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="username"
              className="form-control react-form-input"
              id="username"
              onChange={handleChange}
              value={values.username}
              onBlur={handleBlur}
              placeholder="Email"
            />
            <Error field="username" />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control react-form-input"
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
            />
            <Error field="password" />
          </div>
          <Button
            type="submit"
            dataStyle="expand-left"
            loading={loading}
            className="btn form-button pos-relative"
          >
            Login
          </Button>
          <div
            className="text-center link-label"
            onClick={() => props.history.push("/forgotPassword")}
          >
            Forgot Password ?
          </div>
        </form>
      </div>
      <CustomToast
        heading={snackBar.heading}
        width={400}
        show={snackBar.flag}
        transition
        position="top-middle"
        className={snackBar.className}
        message={snackBar.description}
        onCloseCLick={() => setSnackBar(initSnackBar)}
      />
    </div>
  );
};

export default compose(withRouter, enhancer, connect(null, { login }))(Login);
