import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import CustomToast from "components/notifications/CustomToast";
import OfferWrapper from "./offer.style";
import OfferForm from "components/offers/OfferForm";
import { createOffer, updateOffers } from "api/offers";
import moment from "moment";

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const OfferEdit = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [editedData, setEditedData] = useState(null);
  const hasParam = props.match.params.hasOwnProperty("id");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hasParam) {
      const {
        name,
        type,
        start_date,
        end_date,
        number_of_tickets,
        bonus_amount,
        complimentary_ticket,
        timeframe,
        revenue_sales,
        offer_ticket
      } = props.location.state;

      setEditedData({
        name,
        type,
        start_date,
        end_date,
        number_of_tickets,
        bonus_amount,
        complimentary_ticket,
        timeframe,
        revenue_sales,
        offer_ticket
      });
    }
  }, [props.location.state, hasParam]);

  const submitFormHandler = (data, action) => {
    let newTicketArr = [];
    data.ticket_ids.map(ticketDetails => {
      newTicketArr.push(ticketDetails.value);
    });
    data.ticket_ids = newTicketArr;

    if (data.type === "Bonus") {
      const {
        name,
        type,
        ticket_ids,
        number_of_tickets,
        bonus_amount,
        start_date,
        end_date
      } = data;

      if (action === "add") {
        setLoading(true);
        createOffer({
          name,
          type,
          ticket_ids: JSON.stringify(ticket_ids),
          number_of_tickets,
          bonus_amount,
          start_date: moment(start_date).format("YYYY-MM-DD"),
          end_date: moment(end_date).format("YYYY-MM-DD")
        })
          .then(response => {
            setSnackBar({
              flag: true,
              heading: "Offer Created",
              className: "c-success",
              description: "offer Has been created successfully!"
            });
          })
          .then(response => {
            setLoading(false);
            setTimeout(() => {
              props.history.push("/offers");
            }, 2000);
          })
          .catch(error => {
            setLoading(false);
            setSnackBar({
              flag: true,
              className: "c-danger",
              heading: "Something went wrong",
              description: error.response && error.response.data.message
            });
          });
      } else if (action === "edit") {
        setLoading(true);
        updateOffers(props.match.params.id, {
          name,
          type,
          ticket_ids: JSON.stringify(ticket_ids),
          number_of_tickets,
          bonus_amount,
          start_date: moment(start_date).format("YYYY-MM-DD"),
          end_date: moment(end_date).format("YYYY-MM-DD")
        })
          .then(success => {
            setSnackBar({
              flag: true,
              heading: "Offer Details Saved",
              className: "c-success",
              description: "Offer Has been updated successfully!"
            });
            setLoading(false);
            setTimeout(() => {
              props.history.push("/offers");
            }, 2000);
          })
          .catch(error => {
            setLoading(false);
            setSnackBar({
              flag: true,
              className: "c-danger",
              heading: "Something went wrong",
              description: error.response.data.message
            });
          });
      }
    } else if (data.type === "Complimentary") {
      const {
        name,
        type,
        ticket_ids,
        number_of_tickets,
        complimentary_ticket,
        start_date,
        end_date
      } = data;

      if (action === "add") {
        setLoading(true);
        createOffer({
          name,
          type,
          ticket_ids: JSON.stringify(ticket_ids),
          number_of_tickets,
          complimentary_ticket,
          start_date: moment(start_date).format("YYYY-MM-DD"),
          end_date: moment(end_date).format("YYYY-MM-DD")
        })
          .then(response => {
            setSnackBar({
              flag: true,
              heading: "Offer Created",
              className: "c-success",
              description: "Offer has been created successfully!"
            });
          })
          .then(response => {
            setLoading(false);
            setTimeout(() => {
              props.history.push("/offers");
            }, 2000);
          })
          .catch(error => {
            setLoading(false);
            setSnackBar({
              flag: true,
              className: "c-danger",
              heading: "Something went wrong",
              description: error.response && error.response.data.message
            });
          });
      } else if (action === "edit") {
        setLoading(true);
        updateOffers(props.match.params.id, {
          name,
          type,
          ticket_ids: JSON.stringify(ticket_ids),
          number_of_tickets,
          complimentary_ticket,
          start_date: moment(start_date).format("YYYY-MM-DD"),
          end_date: moment(end_date).format("YYYY-MM-DD")
        })
          .then(success => {
            setSnackBar({
              flag: true,
              heading: "Offer Details Saved",
              className: "c-success",
              description: "Offer Has been updated successfully!"
            });
            setLoading(false);
            setTimeout(() => {
              props.history.push("/offers");
            }, 2000);
          })
          .catch(error => {
            setLoading(false);
            setSnackBar({
              flag: true,
              className: "c-danger",
              heading: "Something went wrong",
              description: error.response.data.message
            });
          });
      }
    } else {
      const {
        name,
        type,
        ticket_ids,
        timeframe,
        revenue_sales,
        bonus_amount,
        start_date,
        end_date
      } = data;

      if (action === "add") {
        setLoading(true);
        createOffer({
          name,
          type,
          ticket_ids: JSON.stringify(ticket_ids),
          timeframe,
          revenue_sales,
          bonus_amount,
          start_date: moment(start_date).format("YYYY-MM-DD"),
          end_date: moment(end_date).format("YYYY-MM-DD")
        })
          .then(response => {
            setSnackBar({
              flag: true,
              heading: "Offer Created",
              className: "c-success",
              description: "Offer has been created successfully!"
            });
          })
          .then(response => {
            setLoading(false);
            setTimeout(() => {
              props.history.push("/offers");
            }, 2000);
          })
          .catch(error => {
            setLoading(false);
            setSnackBar({
              flag: true,
              className: "c-danger",
              heading: "Something went wrong",
              description: error.response && error.response.data.message
            });
          });
      } else if (action === "edit") {
        setLoading(true);
        updateOffers(props.match.params.id, {
          name,
          type,
          ticket_ids: JSON.stringify(ticket_ids),
          timeframe,
          revenue_sales,
          bonus_amount,
          start_date: moment(start_date).format("YYYY-MM-DD"),
          end_date: moment(end_date).format("YYYY-MM-DD")
        })
          .then(success => {
            setSnackBar({
              flag: true,
              heading: "Offer Details Saved",
              className: "c-success",
              description: "Offer Has been updated successfully!"
            });
            setLoading(false);
            setTimeout(() => {
              props.history.push("/offers");
            }, 2000);
          })
          .catch(error => {
            setLoading(false);
            setSnackBar({
              flag: true,
              className: "c-danger",
              heading: "Something went wrong",
              description: error.response.data.message
            });
          });
      }
    }
  };

  return (
    <OfferWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.offers"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.offers"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/offers")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Offer
            </div>
          </div>
          <div className="roe-card-body">
            <OfferForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              loading={loading}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
      </div>
      <CustomToast
        heading={snackBar.heading}
        width={400}
        show={snackBar.flag}
        transition
        position="top-middle"
        className={snackBar.className}
        message={snackBar.description}
        onCloseCLick={() => setSnackBar(initSnackBar)}
      />
    </OfferWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(OfferEdit);
