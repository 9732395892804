import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const login = credential => {
  return axios
    .post(`${BASE_URL}/admin/v1/singin`, credential)
    .then(authDetails => {
      return authDetails.data;
    });
};

const logout = () => {
  return true;
};

const getAdmins = (page, limit, search, sort_by, sort_order) => {
  Headers.setHeaders();

  return axios
    .post(`${BASE_URL}/admin/v1/list`, {
      page,
      limit,
      search,
      sort_by,
      sort_order
    })
    .then(admins => {
      return admins.data;
    });
};

const deleteAdmin = id => {
  Headers.setHeaders();
  return axios.delete(`${BASE_URL}/admin/v1/delete/${id}`).then(res => {
    return res.data;
  });
};

const adminTypes = () => {
  Headers.setHeaders();
  return axios.get(`${BASE_URL}/admin/v1/roles`).then(res => {
    return res.data;
  });
};

const createAdmin = admin => {
  Headers.setHeaders();
  return axios.post(`${BASE_URL}/admin/v1/create`, admin).then(res => {
    return res.data;
  });
};

const updateAdminDetails = (id, admin) => {
  Headers.setHeaders();
  return axios.put(`${BASE_URL}/admin/v1/update/${id}`, admin).then(res => {
    return res.data;
  });
};

const createPassword = (email, password) => {
  return axios
    .post(`${BASE_URL}/admin/v1/reset-password`, { email, password })
    .then(res => {
      return res.data;
    });
};

const forgotPassword = email => {
  return axios
    .post(`${BASE_URL}/admin/v1/forgot-password`, { email })
    .then(res => {
      return res.data;
    });
};

const changePassword = credentails => {
  const { id, oldPassword, newPassword } = credentails;
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/change-password`, {
      admin_id: id,
      old_password: oldPassword,
      password: newPassword
    })
    .then(res => {
      return res.data;
    });
};

const verifyOtp = otp => {
  Headers.setHeaders();
  return axios.post(`${BASE_URL}/admin/v1/verify`, { otp }).then(res => {
    return res.data;
  });
};

const createProfile = data => {
  Headers.setHeaders();
  return axios.post(`${BASE_URL}/admin/v1/profile`, data).then(res => {
    return res.data;
  });
};

const privacyPolicy = () => {
  return axios.get(`${BASE_URL}/admin/v1/pages/privacy-policy`).then(res => {
    return res.data;
  });
};

export {
  login,
  logout,
  getAdmins,
  deleteAdmin,
  adminTypes,
  createAdmin,
  updateAdminDetails,
  createPassword,
  forgotPassword,
  changePassword,
  createProfile,
  verifyOtp,
  privacyPolicy
};
