import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CountUp from "react-countup";
import DashboardWrapper from "./dashboard.style";
import { statistics } from "../api/statistics";
import AuthActions from "../redux/auth/actions";
import { compose } from "redux";
import { withRouter } from "react-router";
import PageTitle from "components/common/PageTitle";

const { logout } = AuthActions;

const Dashboard = props => {
  const [count, setCount] = useState({
    totalAdmins: 0,
    totalCustomers: 0,
    totalReferrals: 0,
    totalEvents: 0,
    totalReviews: 0,
    totalOffers: 0,
    totalComplimentaryTickets: 0
  });

  const { history } = props;
  useEffect(() => {
    statistics()
      .then(res => {
        setCount(res.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardWrapper {...props}>
      <div className="pos-relative">
        <PageTitle title="sidebar.dashboard" className="plr-15" />
      </div>
      <div className="p-5 Grid">
        <div
          className="Card p-3 text-center text-center"
          onClick={() => {
            history.push(`/admins`);
          }}
        >
          <div className="font-style">
            {count && count.totalAdmins > 1 ? "Admins" : "Admin"}
          </div>
          <CountUp className="font-style-2 " end={count && count.totalAdmins} />
        </div>
        <div
          className="Card-2 p-3 text-center text-center"
          onClick={() => {
            history.push(`/customers`);
          }}
        >
          <div className="font-style">
            {count && count.totalCustomers > 1 ? "Customers" : "Customer"}
          </div>
          <CountUp
            className="font-style-2 "
            end={count && count.totalCustomers}
          />
        </div>
        <div
          className="Card-3 p-3 text-center text-center"
          onClick={() => {
            history.push(`/referrals`);
          }}
        >
          <div className="font-style">
            {count && count.totalReferrals > 1 ? "Referrals" : "Referral"}
          </div>
          <CountUp
            className="font-style-2 "
            end={count && count.totalReferrals}
          />
        </div>
      </div>
      <div className="p-5 Grid">
        <div
          className="Card p-3 text-center text-center"
          onClick={() => {
            history.push(`/events`);
          }}
        >
          <div className="font-style">
            {count && count.totalEvents > 1 ? "Events" : "Event"}
          </div>
          <CountUp className="font-style-2 " end={count && count.totalEvents} />
        </div>

        <div
          className="Card p-3 text-center text-center"
          onClick={() => {
            history.push(`/ratings`);
          }}
        >
          <div className="font-style">
            {count && count.totalReviews > 1 ? "Reviews" : "Reviews"}
          </div>
          <CountUp
            className="font-style-2 "
            end={count && count.totalReviews}
          />
        </div>
        <div
          className="Card p-3 text-center text-center"
          onClick={() => {
            history.push(`/offers`);
          }}
        >
          <div className="font-style">
            {count && count.totalOffers > 1 ? "Offers" : "Offer"}
          </div>
          <CountUp className="font-style-2 " end={count && count.totalOffers} />
        </div>
      </div>

      <div className="p-5 grid">
        <div
          className="Card p-3 text-center text-center"
          onClick={() => {
            history.push(`/complimentary-tickets`);
          }}
        >
          <div className="font-style">
            {count && count.totalComplimentaryTickets > 1
              ? "Complimentary Tickets"
              : "Complimentary Ticket"}
          </div>
          <CountUp
            className="font-style-2 "
            end={count && count.totalComplimentaryTickets}
          />
        </div>
      </div>
    </DashboardWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(Dashboard);
