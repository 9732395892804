import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const createPage = page => {
  Headers.setHeaders();
  return axios.post(`${BASE_URL}/admin/v1/pages/add`, page).then(res => {
    return res.data;
  });
};

const savePage = (id, page) => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/pages/update/${id}`, page)
    .then(res => {
      return res.data;
    });
};

const getAllPages = (page, limit, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/pages/list/`, {
      page,
      limit,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      return res.data;
    });
};

export { getAllPages, savePage, createPage };
