import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    title: Yup.string().required("This field is required"),
    subject: Yup.string().required("This field is required"),
    content: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    title: props.editedData ? props.editedData.title : "",
    subject: props.editedData ? props.editedData.subject : "",
    content: props.editedData ? props.editedData.content : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
