import React from "react";
import TicketWrapper from "./ticket.style";
import PageTitle from "components/common/PageTitle";
import TicketTable from "components/tickets/TicketTable";
import { connect } from "react-redux";

const Ticket = props => {
  return (
    <TicketWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.tickets"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.tickets"
            }
          ]}
        />
        {props && props.location && props.location.search && (
          <div
            className="back-icon fs-15 demi-bold-text cursor-pointer"
            onClick={() => props.history.push("/events")}
          >
            <i className="fas fa-step-backward"></i> Back
          </div>
        )}
      </div>
      <div className="user-tbl">
        <TicketTable {...props} />
      </div>
    </TicketWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Ticket);
