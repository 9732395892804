import React from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { ProfileLockScreen } from "helper/constant";

const AdminViewModal = props => {
  const admin = props.admin;
  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Profile Image&nbsp;:&nbsp;
          </label>
          <div id="profile">
            <img
              className="top-header-profile-class"
              src={admin.image || ProfileLockScreen}
              style={{
                height: "125px",
                width: "125px",
                marginBottom: "15px",
                borderRadius: "10px",
                marginTop: "10px"
              }}
              alt="notify"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Name&nbsp;:&nbsp;</label>
          {admin.name}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Email&nbsp;:&nbsp;</label>
          {admin.email}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Phone No&nbsp;:&nbsp;</label>
          {admin.mobile}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Admin Role&nbsp;:&nbsp;</label>
          {admin.admin_role.role}
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(AdminViewModal);
