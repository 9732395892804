import React, { useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import "react-datepicker/dist/react-datepicker.css";
import { ProfileLockScreen } from "helper/constant";
import DatePicker from "react-datepicker";

const EventForm = props => {
  console.log("props", props);
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    loading,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const [uploadImage, setUploadImage] = useState(values.image || null);

  useEffect(() => {
    setUploadImage(values.image);
    if (values.image && typeof values.image === "object") {
      setUploadImage(URL.createObjectURL(values.image));
    }
  }, [values.image]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Image</label>
          <br />
          <div className="file-upload">
            <label
              className="c-btn c-primary form-button fs-16 demi-bold-text mt-15"
              style={{ width: "203px" }}
            >
              Upload Image
              <input
                type="file"
                id="image"
                onChange={e => {
                  setFieldValue("image", e.target.files[0]);
                }}
                onBlur={handleBlur}
                placeholder="image"
                className="file-upload__input"
                name="file-upload"
                accept="image/jpg,image/png,image/jpeg"
              />
            </label>
          </div>
          {
            <img
              id="image"
              alt=""
              className="profile mtb-15"
              src={uploadImage || ProfileLockScreen}
              style={{
                width: "200px",
                borderRadius: "4%",
                height: "150px",
                background: "#404040"
              }}
            />
          }
          <br />
          <Error field="image" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Name EN</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name_en"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name_en}
            placeholder="Name"
          />
          <Error field="name_en" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Name ES</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name_es"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name_es}
            placeholder="Name"
          />
          <Error field="name_es" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Address</label>
          <input
            type="string"
            className="form-control react-form-input"
            id="address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            placeholder="Address"
          />
          <Error field="address" />
        </div>
        {/* <div className="form-group">
          <label className="fs-16 medium-text">Latitude</label>
          <input
            type="number"
            className="form-control react-form-input"
            id="latitude"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.latitude}
            placeholder="Latitude"
          />
          <Error field="latitude" />
        </div> */}
        {/* <div className="form-group">
          <label className="fs-16 medium-text">Longitude</label>
          <input
            type="number"
            className="form-control react-form-input"
            id="longitude"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.longitude}
            placeholder="Longitude"
          />
          <Error field="longitude" />
        </div> */}
        <div className="form-group">
          <label className="fs-16 medium-text">Event Date</label>
          <div>
            <DatePicker
              className="form-control"
              selected={values.event_date}
              id="event_date"
              onChange={e => {
                setFieldValue("event_date", e);
              }}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <Error field="event_date" />
        </div>
        <div>
          <Button
            type="button"
            loading={loading}
            dataStyle="expand-left"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(EventForm);
