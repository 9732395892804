import React from "react";
import ContactWrapper from "./contact.style";
import PageTitle from "components/common/PageTitle";
import ContactTable from "components/contact/ContactTable";
import { connect } from "react-redux";

const Contact = props => {
  return (
    <ContactWrapper {...props}>
      <PageTitle
        title="sidebar.contact"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.contact"
          }
        ]}
      />
      <div className="user-tbl">
        <ContactTable {...props} />
      </div>
    </ContactWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Contact);
