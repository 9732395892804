import React from "react";
import TransactionWrapper from "./transaction.style";
import PageTitle from "components/common/PageTitle";
import WithdrawTable from "components/transaction/WithdrawTable";
import { connect } from "react-redux";

const Withdraw = props => {
  return (
    <TransactionWrapper {...props}>
      <PageTitle
        title="sidebar.transaction"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.withdraw_transaction"
          }
        ]}
      />
      <div className="user-tbl">
        <WithdrawTable {...props} />
      </div>
    </TransactionWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Withdraw);
