import React, { useEffect, useState } from "react";
import StaticPageWrapper from "./mailTemplate.style";
import MailTemplateForm from "components/mailTemplates/MailTemplateForm";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import CustomToast from "components/notifications/CustomToast";
import { saveTemplate, createTemplate } from "../../api/template";

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const StaticPageEdit = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [editedData, setEditedData] = useState(null);
  const hasParam = props.match.params.hasOwnProperty("id");
  const [loading, setLoading] = useState(false);
  const {
    match: { params }
  } = props;

  useEffect(() => {
    setEditedData(props.location.state);
  }, [props.location.state]);

  useEffect(() => {}, [params, hasParam]);

  const submitFormHandler = (data, action) => {
    const { title, subject, content } = data;
    if (action === "add") {
      setLoading(true);
      createTemplate({ title, subject, content })
        .then(response => {
          setSnackBar({
            flag: true,
            heading: "Template Created",
            className: "c-success",
            description: "Template Has been created successfully!"
          });
        })
        .then(response => {
          setLoading(false);
          setTimeout(() => {
            props.history.push("/mail-template");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          if (error && error.response) {
            setSnackBar({
              flag: true,
              className: "c-danger",
              heading: "Something went wrong",
              description: error.response.data.message
            });
          }
        });
    } else if (action === "edit") {
      setLoading(true);
      saveTemplate(props.match.params.id, { title, subject, content })
        .then(response => {
          setSnackBar({
            flag: true,
            heading: "Template Saved",
            className: "c-success",
            description: "Template has been saved successfully!"
          });
        })
        .then(response => {
          setLoading(false);
          setTimeout(() => {
            props.history.push("/mail-template");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          if (error && error.response) {
            setSnackBar({
              flag: true,
              className: "c-danger",
              heading: "Something went wrong",
              description: error.response.data.message
            });
          }
        });
    }
  };

  return (
    <StaticPageWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.mailTemplates"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.mailTemplates"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/mail-template")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Mail
              Template
            </div>
          </div>
          <div className="roe-card-body">
            <MailTemplateForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              loading={loading}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
        <CustomToast
          heading={snackBar.heading}
          width={400}
          show={snackBar.flag}
          transition
          position="top-middle"
          className={snackBar.className}
          message={snackBar.description}
          onCloseCLick={() => setSnackBar(initSnackBar)}
        />
      </div>
    </StaticPageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(StaticPageEdit);
