import React from "react";
import { compose } from "redux";
import enhancer from "./validator";

const SettingViewModal = props => {
  const { setting } = props;

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Key&nbsp;:&nbsp;</label>
          {setting.key}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Value&nbsp;:&nbsp;</label>
          <div
            className="container"
            dangerouslySetInnerHTML={{
              __html: setting.value || ""
            }}
          ></div>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(SettingViewModal);
