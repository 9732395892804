import React, { useState, useEffect } from "react";
import ReferralWrapper from "./Referral.style";
import ReferralForm from "components/referrals/ReferralForm";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import { createReferral, updateReferralDetails } from "../../api/referral";

import CustomToast from "components/notifications/CustomToast";

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const ReferralEdit = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [editedData, setEditedData] = useState(null);
  const hasParam = props.match.params.hasOwnProperty("id");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hasParam) {
      const {
        id,
        first_name,
        last_name,
        email,
        mobile,
        image,
        zip_code,
        introduction,
        is_approved,
        user_documents
      } = props.location.state;

      setEditedData({
        id,
        first_name,
        last_name,
        email,
        mobile,
        image,
        zip_code,
        introduction,
        is_approved,
        user_documents
      });
    }
  }, [props.location.state, hasParam]);

  const submitFormHandler = (data, action) => {
    const {
      id,
      first_name,
      last_name,
      email,
      mobile,
      image,
      zip_code,
      introduction,
      is_approved,
      user_documents
    } = data;

    const formData = new FormData();

    if (action === "add") {
      formData.append("image", image);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("zip_code", zip_code);
      formData.append("introduction", introduction);
      formData.append("is_approved", is_approved);
      //formData.append("user_documents", user_documents);

      setLoading(true);
      createReferral(formData)
        .then(response => {
          setSnackBar({
            flag: true,
            heading: "Referral Created",
            className: "c-success",
            description: "Referral Has been created successfully!"
          });
        })
        .then(response => {
          setLoading(false);
          setTimeout(() => {
            props.history.push("/referrals");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          setSnackBar({
            flag: true,
            className: "c-danger",
            heading: "Something went wrong",
            description: error.response && error.response.data.message
          });
        });
    } else if (action === "edit") {
      if (typeof image === "object") {
        formData.append("image", image);
      }
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("zip_code", zip_code);
      formData.append("introduction", introduction);
      formData.append("is_approved", is_approved);
      //formData.append("user_documents", user_documents);

      setLoading(true);
      updateReferralDetails(props.match.params.id, formData)
        .then(success => {
          setSnackBar({
            flag: true,
            heading: "Referral Details Saved",
            className: "c-success",
            description: "Referral Has been updated successfully!"
          });
          setLoading(false);
          setTimeout(() => {
            props.history.push("/referrals");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          setSnackBar({
            flag: true,
            className: "c-danger",
            heading: "Something went wrong",
            description: error.response.data.message
          });
        });
    }
  };

  return (
    <ReferralWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.referrals"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.referrals"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/referrals")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"}{" "}
              Referral
            </div>
          </div>
          <div className="roe-card-body">
            <ReferralForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              loading={loading}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
      </div>
      <CustomToast
        heading={snackBar.heading}
        width={400}
        show={snackBar.flag}
        transition
        position="top-middle"
        className={snackBar.className}
        message={snackBar.description}
        onCloseCLick={() => setSnackBar(initSnackBar)}
      />
    </ReferralWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(ReferralEdit);
