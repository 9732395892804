import React from "react";
import { compose } from "redux";
import enhancer from "./validator";
import CKEditor from "ckeditor4-react";
import Button from "../button/Button";

const StaticPageForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    submitCount,
    loading,
    onSubmit,
    values,
    action
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Title</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            placeholder="Title"
          />
          <Error field="title" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Content EN</label>
          <div className="App">
            <CKEditor
              data={values.content_en}
              onChange={event => {
                setFieldValue("content_en", event.editor.getData());
              }}
            />
          </div>
          <Error field="content_en" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Content ES</label>
          <div className="App">
            <CKEditor
              data={values.content_es}
              onChange={event => {
                setFieldValue("content_es", event.editor.getData());
              }}
            />
          </div>
          <Error field="content_es" />
        </div>
        <div>
          <Button
            type="button"
            loading={loading}
            dataStyle="expand-left"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(StaticPageForm);
