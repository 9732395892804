import React from "react";
import MailTemplateWrapper from "./mailTemplate.style";
import MailTemplateTable from "components/mailTemplates/MailTemplateTable";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";

const MailTemplate = props => {
  return (
    <MailTemplateWrapper {...props}>
      <PageTitle
        title="sidebar.mailTemplates"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.mailTemplate"
          }
        ]}
      />
      <div className="user-tbl">
        <MailTemplateTable {...props} />
      </div>
    </MailTemplateWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(MailTemplate);
