import React from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { ProfileLockScreen } from "helper/constant";
import moment from "moment";

const CustomerViewModal = props => {
  const customer = props.customer;
  console.log(customer);
  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Profile Image&nbsp;:&nbsp;
          </label>
          <div id="profile">
            <img
              className="top-header-profile-class"
              style={{
                height: "125px",
                width: "125px",
                marginBottom: "15px",
                borderRadius: "10px",
                marginTop: "10px"
              }}
              src={customer.image || ProfileLockScreen}
              alt="notify"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            First&nbsp;Name&nbsp;:&nbsp;
          </label>
          {customer.first_name}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Last&nbsp;Name&nbsp;:&nbsp;
          </label>
          {customer.last_name}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Email&nbsp;:&nbsp;</label>
          {customer.email}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Phone No&nbsp;:&nbsp;</label>
          {customer.mobile}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Unique Id&nbsp;:&nbsp;</label>
          {customer.referral_unique_id}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Referral Code&nbsp;:&nbsp;
          </label>
          {customer.referral_code}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Send Notification&nbsp;:&nbsp;
          </label>
          {customer.is_notification ? "true" : "false"}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Registered Date&nbsp;:&nbsp;
          </label>
          {customer.createdAt}
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(CustomerViewModal);
