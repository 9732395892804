import React from "react";
import ReferralWrapper from "./Referral.style";
import PageTitle from "components/common/PageTitle";
import ReferralTable from "components/referrals/ReferralTable";
import { connect } from "react-redux";

const referral = props => {
  return (
    <ReferralWrapper {...props}>
      <PageTitle
        title="sidebar.referrals"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.referrals"
          }
        ]}
      />
      <div className="user-tbl">
        <ReferralTable {...props} />
      </div>
    </ReferralWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(referral);
