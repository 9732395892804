import React, { useState } from "react";
import { loginBack, ForgotIcon } from "helper/constant";
import { forgotPassword } from "api/admin";
import CustomToast from "components/notifications/CustomToast";
import Button from "components/button/Button";

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const ForgotPassword = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [loading, setLoading] = useState(false);
  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Forgot Password ?</div>
        <div className="text-center form-info-text plr-24 mt-16">
          Provide your e-mail address to reset your password
        </div>
        <form
          className="pa-24"
          onSubmit={e => {
            e.preventDefault();
            if (e.target.email.value) {
              const email = e.target.email.value;
              setLoading(true);
              forgotPassword(email)
                .then(success => {
                  setSnackBar({
                    flag: true,
                    heading: "Mail sent!",
                    className: "c-success",
                    description: success.message
                  });
                })
                .then(res => {
                  setTimeout(() => {
                    setLoading(false);
                    props.history.push(`/otp/${email}`);
                  }, 2000);
                })
                .catch(error => {
                  setLoading(false);
                  setSnackBar({
                    flag: true,
                    className: "c-danger",
                    heading: "Something went wrong",
                    description: error.response.data.message
                  });
                });
            } else {
              setSnackBar({
                flag: true,
                className: "c-danger",
                heading: "Email id required",
                description: ""
              });
            }
          }}
        >
          <div className="form-group">
            <input
              type="email"
              className="form-control react-form-input"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <Button
            type="submit"
            dataStyle="expand-left"
            loading={loading}
            className="pos-relative btn form-button"
          >
            Send Otp
          </Button>
          <div
            className="text-center link-label"
            onClick={() => {
              props.history.push("/login");
            }}
          >
            Login
          </div>
        </form>
      </div>
      <CustomToast
        heading={snackBar.heading}
        width={400}
        show={snackBar.flag}
        transition
        position="top-middle"
        className={snackBar.className}
        message={snackBar.description}
        onCloseCLick={() => setSnackBar(initSnackBar)}
      />
    </div>
  );
};

export default ForgotPassword;
