import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    key: Yup.string().required("This field is required"),
    value: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    key: props.editedData ? props.editedData.key : "",
    value: props.editedData ? props.editedData.value : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
