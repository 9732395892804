import React from "react";
import StaticPageWrapper from "./staticPage.style";
import StaticPageTable from "components/staticPages/StaticPageTable";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";

const StaticPage = props => {
  return (
    <StaticPageWrapper {...props}>
      <PageTitle
        title="sidebar.staticPage"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.staticPage"
          }
        ]}
      />
      <div className="user-tbl">
        <StaticPageTable {...props} />
      </div>
    </StaticPageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(StaticPage);
