import React from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { ProfileLockScreen } from "helper/constant";
import moment from "moment";

const ReferralViewModal = props => {
  const referral = props.referral;
  let documents = "";
  documents = referral.user_documents.map((item, key) => (
    <li key={item.id}>
      <a href={item.document} target="_blank">
        {item.document}
      </a>
    </li>
  ));
  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Profile Image&nbsp;:&nbsp;
          </label>
          <div id="profile">
            <img
              className="top-header-profile-class"
              style={{
                height: "125px",
                width: "125px",
                marginBottom: "15px",
                borderRadius: "10px",
                marginTop: "10px"
              }}
              src={referral.image || ProfileLockScreen}
              alt="notify"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            First&nbsp;Name&nbsp;:&nbsp;
          </label>
          {referral.first_name}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Last&nbsp;Name&nbsp;:&nbsp;
          </label>
          {referral.last_name}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Email&nbsp;:&nbsp;</label>
          {referral.email}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Phone No&nbsp;:&nbsp;</label>
          {referral.mobile}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Zip Code&nbsp;:&nbsp;</label>
          {referral.zip_code}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Introduction&nbsp;:&nbsp;</label>
          {referral.introduction}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Unique Id&nbsp;:&nbsp;</label>
          {referral.referral_unique_id}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Referral Code&nbsp;:&nbsp;
          </label>
          {referral.referral_code}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Is Approved &nbsp;:&nbsp;</label>
          {referral.is_approved == 1
            ? "Approved"
            : referral.is_approved == 2
            ? "Rejected"
            : "Pending"}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Send Notification&nbsp;:&nbsp;
          </label>
          {referral.is_notification ? "true" : "false"}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Registered Date&nbsp;:&nbsp;
          </label>
          {referral.createdAt}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Documents&nbsp;:&nbsp;</label>
          <ul>{documents}</ul>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(ReferralViewModal);
