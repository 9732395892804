import React from "react";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import OfferWrapper from "./offer.style";
import OffersTable from "components/offers/OffersTable";

const Offers = props => {
  return (
    <OfferWrapper {...props}>
      <PageTitle
        title="sidebar.offers"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.offers"
          }
        ]}
      />
      <div className="user-tbl">
        <OffersTable {...props} />
      </div>
    </OfferWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Offers);
