import React, { useEffect, useState } from "react";
import { privacyPolicy } from "api/admin";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

const PrivacyPolicy = () => {
  const [policy, setPolicy] = useState("");

  useEffect(() => {
    privacyPolicy().then(res => {
      console.log("result is", res);
      setPolicy(res.data.content_en);
    });
  }, []);
  return (
    <div style={{ margin: "auto", maxHeight: "100vh", overflow: "auto" }}>
      <h1 style={{ display: "flex", justifyContent: "center", padding: 20 }}>
        Privacy Policy
      </h1>
      <div
        style={{ padding: 30 }}
        dangerouslySetInnerHTML={{ __html: policy }}
      ></div>
    </div>
  );
};

export default compose(withRouter)(PrivacyPolicy);
