import React from "react";
import OfferWrapper from "./offer.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import OfferViewModal from "components/offers/OfferViewModal";

const OfferView = props => {
  const details = props.location.state;

  return (
    <OfferWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.offers"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.offers"
            },
            {
              name: "sidebar.details"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/offers")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> View Offers
            </div>
          </div>
          <div className="roe-card-body">
            <OfferViewModal offers={details} />
          </div>
        </div>
      </div>
    </OfferWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(OfferView);
