import React, { useState, useEffect } from "react";
import AdminWrapper from "./admin.style";
import AdminProfileForm from "components/admins/AdminProfileForm";
import { connect } from "react-redux";
import { compose } from "redux";
import { updateAdminDetails } from "../../api/admin";
import CustomToast from "components/notifications/CustomToast";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import PageTitle from "components/common/PageTitle";

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const { adminD } = AuthActions;

const AdminProfile = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [editedData, setEditedData] = useState(null);
  const hasParam = props.match.params.hasOwnProperty("id");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(props.admin);
    const { mobile, email, name, admin_role_id, image, id } = props.admin;
    setEditedData({
      mobile,
      email,
      name,
      role: admin_role_id,
      image,
      id
    });
  }, [props.admin]);

  const submitFormHandler = data => {
    // const { email } = props.admin;
    const { mobile, name, role, email, id, image } = data;
    const formData = new FormData();
    if (typeof image === "object") {
      formData.append("image", image);
    }
    formData.append("mobile", mobile);
    formData.append("name", name);
    formData.append("admin_role_id", role);
    setLoading(true);
    updateAdminDetails(props.admin.id, formData)
      .then(response => {
        setSnackBar({
          flag: true,
          heading: "Profile Details Saved",
          className: "c-success",
          description: "Profile Details Has been saved successfully!"
        });
        setLoading(false);
        setTimeout(() => {
          const data = {
            // token: response.data && response.data.access_token,
            admin: response.data
          };
          props.adminD(data);
          console.log(props.admin);
        }, 2000);
      })
      .catch(error => {
        setLoading(false);
        if (error && error.response) {
          setSnackBar({
            flag: true,
            className: "c-danger",
            heading: "Something went wrong",
            description: error.response.data.message
          });
        }
      });
  };

  return (
    <AdminWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.profile"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.profile"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.goBack()}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header"></div>
          <div className="roe-card-body">
            <AdminProfileForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              loading={loading}
              action={"edit"}
            />
          </div>
        </div>
      </div>
      <CustomToast
        heading={snackBar.heading}
        width={400}
        show={snackBar.flag}
        transition
        position="top-middle"
        className={snackBar.className}
        message={snackBar.description}
        onCloseCLick={() => setSnackBar(initSnackBar)}
      />
    </AdminWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    admin: state.auth.admin,
    token: state.auth.accessToken
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { adminD })
)(AdminProfile);
