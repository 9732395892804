import React, { useState, useEffect } from "react";
import TicketWrapper from "./ticket.style";
import TicketForm from "components/tickets/TicketForm";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import {
  createTicket,
  updateTicketDetails,
  getTicketDetail
} from "../../api/ticket";

import CustomToast from "components/notifications/CustomToast";
import moment from "moment";

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const TicketEdit = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [editedData, setEditedData] = useState(null);
  const hasParam = props.match.params.hasOwnProperty("id");
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState({});

  useEffect(() => {
    if (hasParam) {
      const {
        id,
        venue,
        event_id,
        ticket_type_id,
        image,
        seating_map_image,
        description_en,
        description_es,
        name_en,
        name_es,
        ticket_type
      } = props.location.state;
      // setEditedData({
      //   id,
      //   event_id,
      //   ticket_type_id,
      //   image,
      //   venue,
      //   seating_map_image,
      //   description_en,
      //   description_es,
      //   name_en,
      //   name_es,
      //   ticket_type,
      // });

      getTicketDetail(id, ticket_type.title)
        .then(data => {
          setEditedData(data.data);
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            props.logout();
          }
        });
    }
  }, [props.location.state, hasParam]);

  const submitFormHandler = (data, action) => {
    const {
      name_en,
      name_es,
      description_en,
      description_es,
      venue,
      image,
      seating_map_image,
      event_id,
      ticket_type_id,
      ticket_infos,
      ticket_levels,
      ticket_transfer_type
    } = data;

    console.log("data is", data);

    const formData = new FormData();
    if (typeof image === "object") {
      formData.append("image", image);
    }
    if (typeof seating_map_image === "object") {
      formData.append("seating_map_image", seating_map_image);
    }
    formData.append("name_en", name_en);
    formData.append("name_es", name_es);
    formData.append("description_en", description_en);
    formData.append("description_es", description_es);
    formData.append("venue", venue);
    formData.append("event_id", event_id);
    formData.append("ticket_type_id", ticket_type_id);
    formData.append("ticket_transfer_type", ticket_transfer_type);
    if (ticket_infos) {
      formData.append("ticket_infos", JSON.stringify(ticket_infos));
    }
    if (ticket_levels) {
      formData.append("ticket_levels", JSON.stringify(ticket_levels));
    }

    if (action === "add") {
      setLoading(true);
      createTicket(formData)
        .then(response => {
          setSnackBar({
            flag: true,
            heading: "Ticket Created",
            className: "c-success",
            description: "Ticket Has been created successfully!"
          });
        })
        .then(response => {
          setLoading(false);
          setTimeout(() => {
            props.history.push("/tickets");
          }, 2000);
        })
        .catch(error => {
          console.log("error", error);
          setLoading(false);
          setSnackBar({
            flag: true,
            className: "c-danger",
            heading: "Something went wrong",
            description: error.response && error.response.data.message
          });
        });
    } else if (action === "edit") {
      setLoading(true);
      updateTicketDetails(props.match.params.id, formData)
        .then(success => {
          setSnackBar({
            flag: true,
            heading: "Ticket Details Saved",
            className: "c-success",
            description: "Ticket Has been updated successfully!"
          });
          setLoading(false);
          setTimeout(() => {
            props.history.push("/tickets");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          setSnackBar({
            flag: true,
            className: "c-danger",
            heading: "Something went wrong",
            description: error.response.data.message
          });
        });
    }
  };

  return (
    <TicketWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.tickets"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.tickets"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/tickets")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"}
              Ticket
            </div>
          </div>
          <div className="roe-card-body">
            <TicketForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              loading={loading}
              ticketData={ticketData}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
      </div>
      <CustomToast
        heading={snackBar.heading}
        width={400}
        show={snackBar.flag}
        transition
        position="top-middle"
        className={snackBar.className}
        message={snackBar.description}
        onCloseCLick={() => setSnackBar(initSnackBar)}
      />
    </TicketWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(TicketEdit);
