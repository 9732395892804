import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    event_id: Yup.number().required("This field is required"),
    ticket_ids: Yup.array()
      .required("This field is required")
      .min(1, "Atleast one ticket is required"),
    number_of_tickets: Yup.number()
      .notRequired()
      .when("type", {
        is: type => type === "Bonus" || type === "Complimentary",
        then: Yup.number().required("This field is required")
      })
  }),
  mapPropsToValues: props => ({
    action: props.action,
    event_id: props.editedData ? props.editedData.event_id : "",
    ticket_ids: props.editedData ? props.editedData.offer_ticket : []
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
