import React, { useState, useEffect } from "react";
import EventWrapper from "./event.style";
import EventForm from "components/events/EventForm";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import { createEvent, updateEventDetails } from "../../api/event";

import CustomToast from "components/notifications/CustomToast";
import moment from "moment";

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const EventEdit = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [editedData, setEditedData] = useState(null);
  const hasParam = props.match.params.hasOwnProperty("id");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hasParam) {
      const {
        id,
        address,
        event_date,
        image,
        longitude,
        latitude,
        name_en,
        name_es
      } = props.location.state;

      setEditedData({
        id,
        address,
        event_date,
        image,
        longitude,
        latitude,
        name_en,
        name_es
      });
    }
  }, [props.location.state, hasParam]);

  const submitFormHandler = (data, action) => {
    const {
      address,
      event_date,
      image,
      //longitude,
      //latitude,
      name_en,
      name_es
    } = data;

    const formData = new FormData();
    if (typeof image === "object") {
      formData.append("image", image);
    }
    formData.append("address", address);
    formData.append("event_date", moment(event_date).format("YYYY-MM-DD"));
    //formData.append("longitude", longitude);
    //formData.append("latitude", latitude);
    formData.append("name_en", name_en);
    formData.append("name_es", name_es);

    if (action === "add") {
      setLoading(true);
      createEvent(formData)
        .then(response => {
          setSnackBar({
            flag: true,
            heading: "Event Created",
            className: "c-success",
            description: "Event Has been created successfully!"
          });
        })
        .then(response => {
          setLoading(false);
          setTimeout(() => {
            props.history.push("/events");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          setSnackBar({
            flag: true,
            className: "c-danger",
            heading: "Something went wrong",
            description: error.response && error.response.data.message
          });
        });
    } else if (action === "edit") {
      setLoading(true);
      updateEventDetails(props.match.params.id, formData)
        .then(success => {
          setSnackBar({
            flag: true,
            heading: "Event Details Saved",
            className: "c-success",
            description: "Event Has been updated successfully!"
          });
          setLoading(false);
          setTimeout(() => {
            props.history.push("/events");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          setSnackBar({
            flag: true,
            className: "c-danger",
            heading: "Something went wrong",
            description: error.response?.data.message
          });
        });
    }
  };

  return (
    <EventWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.event"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.event"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/events")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"}
              Event
            </div>
          </div>
          <div className="roe-card-body">
            <EventForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              loading={loading}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
      </div>
      <CustomToast
        heading={snackBar.heading}
        width={400}
        show={snackBar.flag}
        transition
        position="top-middle"
        className={snackBar.className}
        message={snackBar.description}
        onCloseCLick={() => setSnackBar(initSnackBar)}
      />
    </EventWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(EventEdit);
