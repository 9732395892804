import React from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { ProfileLockScreen } from "helper/constant";
import moment from "moment";

const ComplimentaryViewModal = props => {
  const complimentary = props.complimentary;

  return (
    <div>
      <form>
        <div>
          <div className="form-group">
            <label className="fs-16 medium-text">Offer Name:</label>
            {complimentary.offer_name}
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Number of tickets:</label>
            {complimentary.number_of_tickets}
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Referral Name:</label>
            {complimentary.referrals.first_name +
              " " +
              complimentary.referrals.last_name}
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Ordered:</label>
            {complimentary.is_ordered ? "true" : "false"}
          </div>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(ComplimentaryViewModal);
