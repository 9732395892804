import React from "react";
import { compose } from "redux";
import enhancer from "./changePasswordValidator";
import Button from "../button/Button";

const ChangePasswordForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    loading,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }

    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Current Password</label>
          <input
            type="password"
            className="form-control react-form-input"
            id="oldPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.oldPassword}
            placeholder="Current Password"
          />
          <Error field="oldPassword" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">New Password</label>
          <input
            type="password"
            className="form-control react-form-input"
            id="newPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword}
            placeholder="New Password"
          />
          <Error field="newPassword" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Confirm Password</label>
          <input
            type="password"
            className="form-control react-form-input"
            id="confirmPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmPassword}
            placeholder="Confirm Password"
          />
          <Error field="confirmPassword" />
        </div>
        <div>
          <Button
            type="button"
            loading={loading}
            dataStyle="expand-left"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(ChangePasswordForm);
