import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";
import FileDownload from "js-file-download";

const deleteCustomer = id => {
  Headers.setHeaders();
  return axios.delete(`${BASE_URL}/admin/v1/users/remove/${id}`).then(res => {
    return res.data;
  });
};

const statusChange = id => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/users/update-status/${id}`)
    .then(res => {
      return res.data;
    });
};

const exportUserList = () => {
  Headers.setHeaders();
  return axios.get(`${BASE_URL}/admin/v1/users/export`).then(res => {
    FileDownload(res.data, "UserList.csv");
  });
};

const updateCustomerDetails = (id, customer) => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/users/update/${id}`, customer)
    .then(res => {
      return res.data;
    });
};

const getCustomer = (page, limit, search, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/users/list`, {
      page,
      limit,
      search,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      return res.data;
    });
};

export {
  deleteCustomer,
  statusChange,
  updateCustomerDetails,
  getCustomer,
  exportUserList
};
