import React from "react";

const RatingViewModal = props => {
  const rating = props.rating;
  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Customer Name&nbsp;:&nbsp;
          </label>
          {rating.sender.first_name + " " + rating.sender.last_name}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Referral Name &nbsp;:&nbsp;
          </label>
          {rating.receiver.first_name + " " + rating.receiver.last_name}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Ticket Name&nbsp;:&nbsp;</label>
          {rating.ticket.name_en}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Rating &nbsp;:&nbsp;</label>
          {rating.rating}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Review &nbsp;:&nbsp;</label>
          {rating.review}
        </div>
      </form>
    </div>
  );
};

export default RatingViewModal;
