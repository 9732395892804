import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email id"),
    mobile: Yup.string().required("This field is required"),
    introduction: Yup.string().required("This field is required"),
    zip_code: Yup.string().required("This field is required"),
    is_approved: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    action: props.action,
    image: props.editedData ? props.editedData.image : "",
    first_name: props.editedData ? props.editedData.first_name : "",
    last_name: props.editedData ? props.editedData.last_name : "",
    email: props.editedData ? props.editedData.email : "",
    mobile: props.editedData ? props.editedData.mobile : "",
    introduction: props.editedData ? props.editedData.introduction : "",
    zip_code: props.editedData ? props.editedData.zip_code : "",
    is_approved: props.editedData ? props.editedData.is_approved : "",
    user_documents: props.editedData ? props.editedData.user_documents : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
