import React from "react";
import SettingWrapper from "./Setting.style";
import { connect } from "react-redux";
import SettingViewModal from "components/settings/SettingViewModal";
import PageTitle from "components/common/PageTitle";

const SettingView = props => {
  const details = props.history.location.state;

  return (
    <SettingWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.settings"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.settings"
            },
            {
              name: "sidebar.details"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/Settings")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> View Page
            </div>
          </div>
          <div className="roe-card-body">
            <SettingViewModal Setting={details} />
          </div>
        </div>
      </div>
    </SettingWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(SettingView);
