const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ADMIN: "ADMIN",

  login: data => {
    return {
      type: authActions.LOGIN,
      isLogin: true,
      accessToken: data.token,
      admin: data.admin
    };
  },
  logout: () => {
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null,
      admin: null
    };
  },
  adminD: data => {
    return {
      type: authActions.ADMIN,
      isLogin: true,
      admin: data.admin
    };
  }
};

export default authActions;
