import React from "react";
import { compose } from "redux";
import { Table } from "reactstrap";
import moment from "moment";

const TransferViewModal = props => {
  const transfer = props.transfer;
  console.log("transfer", transfer);
  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Customer&nbsp;Name&nbsp;:&nbsp;
          </label>
          {transfer.order.users.first_name +
            " " +
            transfer.order.users.last_name}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Customer&nbsp;Email&nbsp;:&nbsp;
          </label>
          {transfer.order.users.email}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Customer&nbsp;Mobile&nbsp;:&nbsp;
          </label>
          {transfer.order.users.mobile}
        </div>
        {transfer.order.referrals !== null ? (
          <div className="form-group">
            <label className="fs-16 medium-text">
              Referral&nbsp;Name&nbsp;:&nbsp;
            </label>
            {transfer.order.referrals?.first_name +
              " " +
              transfer.order.referrals?.last_name}
          </div>
        ) : (
          ""
        )}

        <div className="form-group">
          <label className="fs-16 medium-text">Event Name:&nbsp;</label>
          {transfer.event.name_en}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Event Date:</label>
          {moment(transfer.event.event_date).format("YYYY-MM-DD")}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Ticket Name:&nbsp;</label>
          {transfer.ticket.name_en}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Ticket Description:&nbsp;</label>
          {transfer.ticket.description_en}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Venue:&nbsp;</label>
          {transfer.ticket.venue}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Ticket Transfer Type:&nbsp;
          </label>
          {transfer.ticket.ticket_transfer_type}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Ticket Type:&nbsp;</label>
          {transfer.ticket.ticket_type.title}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Total Ticket Price:&nbsp;</label>
          {transfer.ticket_total_amount}
        </div>
      </form>
      <Table>
        <tbody>
          <tr>
            <th>Price</th>
            <th>Quantity</th>
            <th>Start Seat Number</th>
            <th>End Seat Number</th>
          </tr>
          <tr>
            <td> {transfer.ticket_info.price}</td>
            <td>{transfer.ticket_info.quantity}</td>
            <td> {transfer.ticket_info.seat}</td>
            <td> {transfer.ticket_info.end_seat_number}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TransferViewModal;
