import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const deleteReferral = id => {
  Headers.setHeaders();
  return axios
    .delete(`${BASE_URL}/admin/v1/referrals/remove/${id}`)
    .then(res => {
      return res.data;
    });
};

const statusChange = id => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/referrals/update-status/${id}`)
    .then(res => {
      return res.data;
    });
};

const updateReferralDetails = (id, referral) => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/referrals/update/${id}`, referral)
    .then(res => {
      return res.data;
    });
};

const getReferral = (page, limit, search, field, order) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/referrals/list`, {
      page,
      limit,
      search,
      sort_by: field,
      sort_order: order
    })
    .then(res => {
      return res.data;
    });
};

const getReferralAvgRatingList = (page, limit, field, order, referral_id) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/referrals/avg-rating-list`, {
      page,
      limit,
      sort_by: field,
      sort_order: order,
      referral_id
    })
    .then(res => {
      return res.data;
    });
};

const getReferralLowRatingList = (page, limit, field, order, referral_id) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/referrals/low-rating-list`, {
      page,
      limit,
      sort_by: field,
      sort_order: order,
      referral_id
    })
    .then(res => {
      return res.data;
    });
};

const createReferral = data => {
  Headers.setHeaders();
  return axios.post(`${BASE_URL}/admin/v1/referrals/add`, data).then(res => {
    return res.data;
  });
};

const changeStatusApproved = (id, status) => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/referrals/update-approve-status/${id}`, {
      is_approved: status
    })
    .then(res => {
      return res.data;
    });
};

export {
  deleteReferral,
  statusChange,
  updateReferralDetails,
  getReferral,
  createReferral,
  changeStatusApproved,
  getReferralAvgRatingList,
  getReferralLowRatingList
};
