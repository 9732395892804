import styled from "styled-components";

const RatingAverageWrapper = styled.div`
  .ant-divider-vertical {
    border-left: 1px solid #e0e0e0 !important;
    height: 100px;
  }

  .grid {
    display: grid;
    grid-template-columns: 5% 5% 60% 30%;
  }
`;

export default RatingAverageWrapper;
