import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required("This field is required"),
    type: Yup.string().required("This field is required"),
    ticket_ids: Yup.array()
      .required("This field is required")
      .min(1, "Atleast one ticket is required"),
    start_date: Yup.string().required("This field is required"),
    end_date: Yup.string().required("This field is required"),
    number_of_tickets: Yup.number()
      .notRequired()
      .when("type", {
        is: type => type === "Bonus" || type === "Complimentary",
        then: Yup.number().required("This field is required")
      }),
    bonus_amount: Yup.number()
      .notRequired()
      .when("type", {
        is: type => type === "Bonus" || type === "Timeframe",
        then: Yup.number().required("This field is required")
      }),
    complimentary_ticket: Yup.number()
      .notRequired()
      .when("type", {
        is: type => type === "Complimentary",
        then: Yup.number().required("This field is required")
      }),
    timeframe: Yup.number()
      .notRequired()
      .when("type", {
        is: type => type === "Timeframe",
        then: Yup.number().required("This field is required")
      }),
    revenue_sales: Yup.number()
      .notRequired()
      .when("type", {
        is: type => type === "Timeframe",
        then: Yup.number().required("This field is required")
      })
  }),
  mapPropsToValues: props => ({
    action: props.action,
    name: props.editedData ? props.editedData.name : "",
    type: props.editedData ? props.editedData.type : "",
    ticket_ids: props.editedData ? props.editedData.offer_ticket : [],
    start_date: props.editedData ? Date.parse(props.editedData.start_date) : "",
    end_date: props.editedData ? Date.parse(props.editedData.end_date) : "",
    number_of_tickets: props.editedData
      ? Number(props.editedData.number_of_tickets)
      : "",
    bonus_amount: props.editedData ? Number(props.editedData.bonus_amount) : "",
    complimentary_ticket: props.editedData
      ? Number(props.editedData.complimentary_ticket)
      : "",
    timeframe: props.editedData ? Number(props.editedData.timeframe) : "",
    revenue_sales: props.editedData
      ? Number(props.editedData.revenue_sales)
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
