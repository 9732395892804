import axios from "axios";
import { BASE_URL } from "../helper/global";
import Headers from "./header";

const deleteTickets = id => {
  Headers.setHeaders();
  return axios.delete(`${BASE_URL}/admin/v1/tickets/remove/${id}`).then(res => {
    return res.data;
  });
};

const createTicket = ticket => {
  Headers.setHeaders();
  return axios.post(`${BASE_URL}/admin/v1/tickets/add`, ticket).then(res => {
    return res.data;
  });
};

const statusChange = id => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/tickets/update-status/${id}`)
    .then(res => {
      return res.data;
    });
};

const updateTicketDetails = (id, customer) => {
  Headers.setHeaders();
  return axios
    .put(`${BASE_URL}/admin/v1/tickets/update/${id}`, customer)
    .then(res => {
      return res.data;
    });
};

const getTickets = (page, limit, search, field, order, eventId) => {
  Headers.setHeaders();
  return axios
    .post(`${BASE_URL}/admin/v1/tickets/list`, {
      page,
      limit,
      search,
      sort_by: field,
      sort_order: order,
      eventId
    })
    .then(res => {
      return res.data;
    });
};

const allEvents = () => {
  Headers.setHeaders();
  return axios.get(`${BASE_URL}/admin/v1/events/all-events`).then(res => {
    return res.data;
  });
};

const ticketTypeList = () => {
  Headers.setHeaders();
  return axios.get(`${BASE_URL}/admin/v1/tickets/ticket-types`).then(res => {
    return res.data;
  });
};

const getTicketDetail = (ticket_id, ticket_type) => {
  Headers.setHeaders();
  return axios
    .get(`${BASE_URL}/admin/v1/tickets/view/${ticket_id}/${ticket_type}`)
    .then(res => {
      return res.data;
    });
};

export {
  deleteTickets,
  createTicket,
  statusChange,
  updateTicketDetails,
  getTickets,
  getTicketDetail,
  allEvents,
  ticketTypeList
};
