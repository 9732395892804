import React from "react";
import OrderWrapper from "./order.style";
import PageTitle from "components/common/PageTitle";
import OrderTable from "components/order/OrderTable";
import { connect } from "react-redux";

const Order = props => {
  return (
    <OrderWrapper {...props}>
      <PageTitle
        title="sidebar.order"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard"
          },
          {
            name: "sidebar.order"
          }
        ]}
      />
      <div className="user-tbl">
        <OrderTable {...props} />
      </div>
    </OrderWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Order);
