import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name_en: Yup.string().required("This field is required"),
    name_es: Yup.string().required("This field is required"),
    venue: Yup.string().required("This field is required"),
    description_en: Yup.string().required("This field is required"),
    description_es: Yup.string().required("This field is required"),
    event_id: Yup.number().required("This field is required"),
    ticket_type_id: Yup.number().required("This field is required")
    //ticket_transfer_type: Yup.string().required("This field is required"),
    //ticket_infos : Yup.array().required('This field is required').min(1,'Required'),
  }),
  mapPropsToValues: props => ({
    action: props.action,
    image: props.editedData ? props.editedData.image : "",
    seating_map_image: props.editedData
      ? props.editedData.seating_map_image
      : "",
    name_en: props.editedData ? props.editedData.name_en : "",
    name_es: props.editedData ? props.editedData.name_es : "",
    venue: props.editedData ? props.editedData.venue : "",
    description_en: props.editedData ? props.editedData.description_en : "",
    description_es: props.editedData ? props.editedData.description_es : "",
    event_id: props.editedData ? props.editedData.event_id : "",
    ticket_type_id: props.editedData ? props.editedData.ticket_type_id : "",
    ticket_infos: props.editedData ? props.editedData.ticket_infos : null,
    ticket_levels: props.editedData ? props.editedData.ticket_levels : null,
    ticket_transfer_type: props.editedData
      ? props.editedData.ticket_transfer_type
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
