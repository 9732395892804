import React, { useEffect, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { Card, Divider, Rate, Space, Progress, Row, Col, Spin } from "antd";
import "antd/dist/antd.css";
import star from "../../assets/images/themes/star.svg";
import goldstar from "../../assets/images/themes/goldstar.svg";
import patient1 from "../../assets/images/themes/patient1.svg";
import RatingAverageWrapper from "./AverageRating.style";
import { getReferralAvgRatingList } from "../../api/referral";
import moment from "moment";
import Pagination from "components/common/Pagination";
import LoaderComponent from "components/common/LoaderComponent";

const ReferralAverageRatingViewModal = props => {
  const [referalDetails, setReferalDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(50);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState(null);
  const [order, setOrder] = useState(null);
  const [countRatings, setCountRatings] = useState([]);
  const [countValue, setCountValue] = useState(null);
  const [changeMethodFlag] = useState(false);

  useEffect(() => {
    setReferralData(activePage + 1, 10, field, order);
  }, [activePage, field, order]);

  const setReferralData = (page, limit, field, order, referral_id) => {
    setLoader(true);
    getReferralAvgRatingList(page, limit, field, order, referral.id)
      .then(data => {
        setPages(Math.ceil(data.data.rows.count / 10));
        setReferalDetails(data.data.rows.rows);
        setCountRatings(data.data.rows.count_ratings);
        setCountValue(data.data.rows.count);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        if (error.response && error.response.status === 401) {
          props.logout();
        }
      });
  };

  const onChangePageGetData = state => {
    if (activePage !== null) {
      const sorted = state.sorted;
      if (sorted.length > 0) {
        setField(sorted[0].id);
        setOrder(sorted[0].desc ? "DESC" : "ASC");
      }
    }
  };
  const referral = props.referral;
  return loader ? (
    <Spin size="large"></Spin>
  ) : (
    <div>
      <div>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="margin-auto">
              <Space>
                <div className="P-SemiBold fs-40">{referral.avg_ratings}</div>
                <img src={goldstar} alt="loading..." height="24" />
              </Space>
              <div className="pt-10 grey-text P-Regular">
                {countValue} ratings and reviews
              </div>
            </div>
          </Col>
          <div>
            <Divider type="horizontal" />
          </div>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div>
              {countRatings &&
                countRatings.map((res, index) => {
                  if (index > 5) {
                    return;
                  } else {
                    return (
                      <RatingAverageWrapper>
                        <div>
                          {Object.keys(countRatings[0]).map(ratingValue => {
                            if (ratingValue === "avg_ratings") {
                              return;
                            } else {
                              return (
                                <div className="grid">
                                  <div>
                                    {ratingValue === "r5"
                                      ? 5
                                      : ratingValue === "r4"
                                      ? 4
                                      : ratingValue === "r3"
                                      ? 3
                                      : ratingValue === "r2"
                                      ? 2
                                      : ratingValue === "r1"
                                      ? 1
                                      : ""}
                                  </div>
                                  <div>
                                    <img src={star} alt="loading..." />
                                  </div>
                                  <div>
                                    <Progress
                                      percent={
                                        (countRatings[0][ratingValue] /
                                          countValue) *
                                        100
                                      }
                                      showInfo={false}
                                      strokeColor={
                                        ratingValue === "r5" ||
                                        ratingValue === "r4"
                                          ? "green"
                                          : ratingValue === "r3"
                                          ? "yellow"
                                          : ratingValue === "r2" ||
                                            ratingValue === "r1"
                                          ? "red"
                                          : ""
                                      }
                                    />
                                  </div>
                                  <Space>
                                    <span className="P-Regular grey-text">
                                      {countRatings[0][ratingValue]}
                                    </span>
                                  </Space>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </RatingAverageWrapper>
                    );
                  }
                })}
            </div>
          </Col>
        </Row>

        <hr></hr>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              {referalDetails &&
                referalDetails.map(result => (
                  <Card className="review-box">
                    <div>
                      <div className="header">
                        <Space size="large">
                          <div className="reviews-left flex">
                            <div className="mr-20">
                              <img
                                className="sender-image"
                                src={result.sender.image}
                                alt="loading..."
                                style={{ borderRadius: "50%" }}
                              />
                            </div>
                            <div>
                              <div className="P-SemiBold">
                                {result.sender.first_name +
                                  " " +
                                  result.sender.last_name}
                              </div>
                              <Rate disabled defaultValue={result.rating} />
                              <div>{result.ticket.name_en}</div>
                            </div>
                          </div>
                        </Space>
                        <div className="P-Regular grey-text reviews-right">
                          {moment(result.createdAt).format("DD/MM/YYYY")}
                        </div>
                      </div>
                      <div className="pt-20 P-Regular">{result.review}</div>
                    </div>
                  </Card>
                ))}
            </Space>
          </Col>
        </Row>
        <Pagination
          pages={pages}
          page={activePage}
          LoadingComponent={LoaderComponent}
          loading={loader}
          onFetchData={onChangePageGetData}
          onPageChange={pageIndex => setActivePage(pageIndex)}
          changeMethodFlag={changeMethodFlag}
        ></Pagination>
      </div>
    </div>
  );
};

export default compose(enhancer)(ReferralAverageRatingViewModal);
