import React, { useEffect, useState } from "react";
import StaticPageWrapper from "./staticPage.style";
import StaticPageForm from "components/staticPages/StaticPageForm";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import CustomToast from "components/notifications/CustomToast";
import { savePage, createPage } from "../../api/page";

const initSnackBar = {
  flag: false,
  heading: "",
  className: "c-success",
  description: ""
};

const StaticPageEdit = props => {
  const [snackBar, setSnackBar] = useState(initSnackBar);
  const [editedData, setEditedData] = useState(null);
  const hasParam = props.match.params.hasOwnProperty("id");
  const [loading, setLoading] = useState(false);
  const {
    match: { params }
  } = props;

  useEffect(() => {
    setEditedData(props.location.state);
  }, [props.location.state]);

  useEffect(() => {}, [params, hasParam]);

  const submitFormHandler = (data, action) => {
    const { title, content_en, content_es } = data;
    if (action === "add") {
      setLoading(true);
      createPage({ title, content_en, content_es })
        .then(response => {
          setSnackBar({
            flag: true,
            heading: "Page Created",
            className: "c-success",
            description: "page Has been created successfully!"
          });
        })
        .then(response => {
          setLoading(false);
          setTimeout(() => {
            props.history.push("/staticPages");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          if (error && error.response) {
            setSnackBar({
              flag: true,
              className: "c-danger",
              heading: "Something went wrong",
              description: error.response.data.message
            });
          }
        });
    } else if (action === "edit") {
      setLoading(true);
      savePage(props.match.params.id, { title, content_en, content_es })
        .then(response => {
          setSnackBar({
            flag: true,
            heading: "Page Saved",
            className: "c-success",
            description: "page Has been saved successfully!"
          });
        })
        .then(response => {
          setLoading(false);
          setTimeout(() => {
            props.history.push("/staticPages");
          }, 2000);
        })
        .catch(error => {
          setLoading(false);
          if (error && error.response) {
            setSnackBar({
              flag: true,
              className: "c-danger",
              heading: "Something went wrong",
              description: error.response.data.message
            });
          }
        });
    }
  };

  return (
    <StaticPageWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.staticPage"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard"
            },
            {
              name: "sidebar.staticPage"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/staticPages")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Page
            </div>
          </div>
          <div className="roe-card-body">
            <StaticPageForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              loading={loading}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
        <CustomToast
          heading={snackBar.heading}
          width={400}
          show={snackBar.flag}
          transition
          position="top-middle"
          className={snackBar.className}
          message={snackBar.description}
          onCloseCLick={() => setSnackBar(initSnackBar)}
        />
      </div>
    </StaticPageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(StaticPageEdit);
