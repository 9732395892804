import React from "react";
import { compose } from "redux";
import enhancer from "./validator";

const MailTemplateViewModal = props => {
  const { mailTemplate } = props;

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Title&nbsp;:&nbsp;</label>
          {mailTemplate.title}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Subject&nbsp;:&nbsp;</label>
          {mailTemplate.subject}
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Content&nbsp;:&nbsp;</label>
          <div
            className="container"
            dangerouslySetInnerHTML={{
              __html: mailTemplate.content || ""
            }}
          ></div>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(MailTemplateViewModal);
