import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    title: Yup.string().required("This field is required"),
    content_en: Yup.string().required("This field is required"),
    content_es: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    title: props.editedData ? props.editedData.title : "",
    content_en: props.editedData ? props.editedData.content_en : "",
    content_es: props.editedData ? props.editedData.content_es : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
